import PropTypes from 'prop-types';
import {ALL_COUNTRIES} from './country';
import {addressPropTypes} from './address';

export const interoperatedOrganisationPropTypes = () => PropTypes.shape({
  masterPlatform: PropTypes.string,
  country: PropTypes.oneOf(ALL_COUNTRIES),
  enabled: PropTypes.bool.isRequired
});

export const identificationNumberPropTypes = () => PropTypes.shape({
  id: PropTypes.number,
  value: PropTypes.string,
  code: PropTypes.string
});

export const organisationPropTypes = () => PropTypes.shape({
  organisationId: PropTypes.number.isRequired,
  registrationNumber: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  country: PropTypes.oneOf(ALL_COUNTRIES).isRequired,
  address: addressPropTypes().isRequired,
  enabled: PropTypes.bool.isRequired,
  groupId: PropTypes.number.isRequired,
  planSelected: PropTypes.bool,
  interoperatedOrganisation: interoperatedOrganisationPropTypes(),
  identificationNumbers: PropTypes.object
});

export const REGISTRATION_NUMBER_CODE = 'REGISTRATION_NUMBER';
export const GROUP_NUMBER_CODE = 'GROUP_NUMBER';
export const VAT_IDENTIFICATION_NUMBER_CODE = 'VAT_IDENTIFICATION_NUMBER';

export const getGroupNumberFromOrganisation = organisation => {
  const groupNumberIdentificationNumber = organisation.identificationNumbers[GROUP_NUMBER_CODE];
  if (groupNumberIdentificationNumber) {
    return groupNumberIdentificationNumber;
  }

  return null;
};

export const getVATIdentificationNumberFromOrganisation = organisation => {
  const vatIdentificationNumber = organisation.identificationNumbers[VAT_IDENTIFICATION_NUMBER_CODE];
  if (vatIdentificationNumber) {
    return vatIdentificationNumber;
  }

  return null;
};

const organisations = {
  interoperatedOrganisationPropTypes,
  organisationPropTypes,
  REGISTRATION_NUMBER_CODE,
  GROUP_NUMBER_CODE,
  VAT_IDENTIFICATION_NUMBER_CODE,
  getGroupNumberFromOrganisation,
  getVATIdentificationNumberFromOrganisation
};

export default organisations;
