import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Tooltip} from '@material-ui/core';
import {organisationPropTypes} from '../../utils/organisations';

function OperaStatus(props) {
  if (!props.organisation ||
    !props.organisation.interoperatedOrganisation ||
    !props.organisation.interoperatedOrganisation.enabled ||
    (props.masterPlatformToShow && props.masterPlatformToShow !== props.organisation.interoperatedOrganisation.masterPlatform)) {
    return null;
  }

  const {masterPlatform} = props.organisation.interoperatedOrganisation;
  return (
    <div data-id="render-opera-status">
      <Tooltip title={<Translate value={`opera.platform-${masterPlatform}.message`}/>} placement="bottom">
        {props.small ? <img src="/resources/img/icon_opera_small.png" alt="opera"/> : <img src="/resources/img/icon_opera.png" alt="opera"/>}
      </Tooltip>
    </div>
  );
}

OperaStatus.defaultProps = {
  small: false
};

OperaStatus.propTypes = {
  organisation: organisationPropTypes(),
  small: PropTypes.bool,
  masterPlatformToShow: PropTypes.string
};

export default OperaStatus;
