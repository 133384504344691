import {DOCUMENT_STATE} from '../utils/documents';
import {COUNTRY} from '../utils/country';
import {VAT_IDENTIFICATION_NUMBER_CODE, GROUP_NUMBER_CODE} from '../utils/organisations';

export const documentTypesInDb = [{
  code: 'REG_BUSINESS',
  documentTypeId: 1,
  name: 'Gewerbeanmeldung',
  parentCode: 'REGISTRATION',
  parentName: 'Registrierungsnachweis',
  validityPeriod: 12,
  gatheredByDepositor: true
},
{
  code: 'REG_COMPANY',
  documentTypeId: 2,
  name: 'Eintragung IHK',
  parentCode: 'REGISTRATION',
  parentName: 'Registrierungsnachweis',
  gatheredByDepositor: true
},
{
  code: 'REG_ARTISAN',
  documentTypeId: 3,
  name: 'Eintragung HWK',
  parentCode: 'REGISTRATION',
  parentName: 'Registrierungsnachweis',
  gatheredByDepositor: true
},
{
  code: 'INS_LIABILITY',
  documentTypeId: 4,
  name: 'Nachweis Haftpflichtversicherung',
  parentCode: 'INSURANCE',
  parentName: 'Versicherungsbescheinigung',
  gatheredByDepositor: true
},
{
  code: 'INS_LIA_POLICE',
  documentTypeId: 5,
  name: 'Haftpflichtversicherungspolice',
  parentCode: 'INSURANCE',
  parentName: 'Versicherungsbescheinigung',
  gatheredByDepositor: true
},
{
  code: 'EMPLOYEES',
  documentTypeId: 6,
  name: 'Nachweis Haftpflichtversicherung',
  parentCode: 'EMPLOYEES_LIST',
  parentName: 'Arbeitnehmerliste',
  gatheredByDepositor: true
},
{
  code: 'FR_COV',
  documentTypeId: 29,
  name: 'Attestation de vigilance',
  parentCode: 'COVERAGE',
  parentName: 'Attestation de vigilance',
  gatheredByDepositor: false
},
{
  code: 'COV_VIGI',
  documentTypeId: 27,
  name: 'Attestation Sociale de vigilance',
  parentCode: 'COVERAGE',
  parentName: 'Attestation de vigilance',
  gatheredByDepositor: false
},
{
  code: 'FR_PAY',
  documentTypeId: 36,
  name: 'Attestation Congés payés',
  parentCode: 'PAID_LEAVE',
  parentName: 'Attestation Congés payés et intempéries',
  gatheredByDepositor: false
},
{
  code: 'INS_RC_PRO',
  documentTypeId: 53,
  name: 'Assurance RC Professionnelle',
  parentCode: 'INSURANCE',
  parentName: 'Attestation d\'assurance',
  gatheredByDepositor: false
},
{
  code: 'TAX_INDEPENDENT',
  documentTypeId: 50,
  name: 'Attestation fiscale société indépendante',
  parentCode: 'INSURANCE',
  parentName: 'Attestation d\'assurance',
  gatheredByDepositor: false
}];

export const generateUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  // eslint-disable-next-line no-bitwise
  const r = Math.random() * 16 | 0;
  // eslint-disable-next-line no-mixed-operators, no-bitwise
  const v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const anAddress = custom => ({addressLine: '119 rue francis de préssensé',
  city: 'Villeurbanne',
  department: 'Rhône',
  region: 'Rhône',
  postCode: '69001',
  ...custom});

export const anInteroperatedOrganisation = custom => ({masterPlatform: null,
  country: COUNTRY.FRANCE,
  enabled: false,
  ...custom});

export const anIdentificationNumberMap = (...args) => Object.assign({}, ...args);

export const aGroupNumberIdentificationNumber = value => ({[GROUP_NUMBER_CODE]: value || '84230331'});

export const aVATIdentificationNumber = value => ({[VAT_IDENTIFICATION_NUMBER_CODE]: value || 'FR11842303315'});

export const anOrganisation = custom => ({organisationId: 1002,
  fullName: 'Papier Craft',
  country: COUNTRY.FRANCE,
  businessLine: 'CONSTRUCTION',
  address: anAddress(),
  registrationNumber: '84230331500018',
  depositor: true,
  groupId: 1,
  enabled: false,
  planSelected: true,
  interoperatedOrganisation: anInteroperatedOrganisation(),
  identificationNumbers: anIdentificationNumberMap(aGroupNumberIdentificationNumber(), aVATIdentificationNumber()),
  ...custom});

export const aDocumentEquivalence = custom => ({rule: 'MULTIPLE', ...custom});

export const aDocumentType = custom => ({code: 'INS_RC_PRO',
  documentTypeId: 53,
  name: 'Assurance RC Professionnelle',
  parentCode: 'INSURANCE',
  parentName: 'Attestation d\'assurance',
  gatheredByDepositor: true,
  multiOccurrence: false,
  validityPeriod: null,
  designationMandatory: false,
  abstractType: false,
  hasExample: true,
  applicationLevel: 'GROUP',
  certifyingThirdPartyMandatory: 'REQUIRED',
  issuingThirdPartyMandatory: 'OPTIONAL',
  thirdPartyIdNumberMandatory: 'REQUIRED',
  onlyForProjectSubcontractor: false,
  collectableByMandate: true,
  equivalence: null,
  canBeAdded: true,
  dossier: 'BUSINESS',
  ...custom});

export const aDossier = custom => ({type: 'BUSINESS',
  client: null,
  project: null,
  business: 'CONSTRUCTION',
  ...custom});

export const aLegalDossier = custom => ({...aDossier({
  type: 'LEGAL'
}),
...custom});
export const anAdditionalDossier = custom => ({...aDossier({
  type: 'ADDITIONAL'
}),
...custom});

export const aDocument = custom => ({uid: generateUID(),
  groupLevel: true,
  state: DOCUMENT_STATE.VALID,
  type: aDocumentType(),
  depositDate: '2019-04-25T13:35:13.424Z',
  organisations: [anOrganisation()],
  validityEnd: '2019-12-31T22:59:59.999Z',
  hasFile: true,
  md5: '92cac928300f34d64c7809eaaee4e10b',
  hasExcelFile: false,
  noticePeriodConfiguration: 'LOW',
  firstNoticePeriod: 15,
  secondNoticePeriod: 10,
  thirdNoticePeriod: 5,
  issueDate: '2019-01-17T00:00:00Z',
  validityStart: '2019-01-17T00:00:00Z',
  certifyingThirdParty: 'Wemind',
  issuingThirdParty: 'Wemind',
  thirdPartyIdNumber: null,
  rejectionReason: null,
  additionalDesignation: '',
  subjectToObligation: true,
  gatheredByDepositor: true,
  collectionMandateEnabled: false,
  toFillinOnline: false,
  toSignOnline: false,
  dossiers: [aDossier()],
  unpublished: false,
  correlationId: generateUID(),
  verificationCode: null,
  hasProofFile: false,
  referencePeriod: null,
  payroll: null,
  staff: null,
  withCapitalizationScheme: false,
  amountBankGuarantee: null,
  turnover: null,
  iban: null,
  bic: null,
  contributionRate: null,
  turnoverTwa: null,
  referenceYear: null,
  legacy: false,
  overallStatusDocument: false,
  ...custom});

export const aDocumentVersion = custom => ({
  ...aDocument(),
  hasAnotherOccurrenceInSameDossier: false,
  ...custom
});

export const aDocumentPermissions = custom => ({
  canEditAdditionalDesignation: true,
  canSeeSignAction: true,
  canSign: true,
  canUpload: true,
  canUploadByMandate: true,
  canDownload: true,
  canDownloadExcel: true,
  canDownloadWithInvalidState: true,
  canDownloadProof: true,
  canDuplicate: true,
  canDelete: true,
  isBackOffice: true,
  ...custom
});

const documentTestUtils = {
  anAddress,
  anOrganisation,
  aDocumentEquivalence,
  aDocumentType,
  aDossier,
  aLegalDossier,
  anAdditionalDossier,
  aDocument,
  aDocumentVersion,
  aDocumentPermissions,
  anInteroperatedOrganisation
};

export default documentTestUtils;
