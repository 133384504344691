export const mandateFieldTypes = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE'
};

export const mandateWebsites = {
  AWS: 'https://awsolutions.fr/apr/',
  CIBTP: 'https://www.cibtp.fr',
  CNETP: 'https://conges.cnetp.fr/cnetpAdherent/',
  EATTESTATIONS: 'https://declarants.e-attestations.com/EAttestationsFO/fo/E-Attestations.html',
  FR_TAX: 'https://cfspro.impots.gouv.fr/LoginAccess',
  MYPROCUREMENT: 'https://www.myprocurement.fr/universe/public/login/login.action',
  NET_ENTREPRISE: 'https://portail.net-entreprises.fr/auth/',
  PROBTP: 'https://www.probtp.com/mon-compte-entreprise.html',
  PROVIGIS: 'https://app.provigis.com/login#/index/',
  TESE: 'https://www2.letese.urssaf.fr/cetpeweb/connectempl.jsp',
  URSSAF: 'https://www.urssaf.fr/portail/home.html',
  URSSAF_AUTOENT: 'https://www.autoentrepreneur.urssaf.fr/portail/accueil.html',
  URSSAF_INDEP: 'https://www.urssaf.fr/portail/home.html'
};

export const mandateSelectValues = [
  {
    key: 'REGION',
    thirdPartyGroup: 'CIBTP',
    values: ['Centre-Ouest', 'Grand Est', 'Grand Ouest', 'Guadeloupe', 'Guyane', 'Ile-de-France', 'La Réunion',
      'Martinique', 'Méditerranée', 'Nord-Ouest', 'Région du Centre', 'Rhône-Alpes Auvergne', 'Sud-Ouest']
  }
];

export default {
  mandateFieldTypes,
  mandateWebsites,
  mandateSelectValues
};
