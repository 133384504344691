import {Translate} from 'react-redux-i18n';
import {
  canBeDeleted,
  documentActionsPropTypes,
  documentPermissionsPropTypes,
  documentVersionPropTypes,
  isMultiOccurrence
} from '../../../utils/documents';
import {isEmpty} from '../../../utils/arrays';
import ThreeDotsMenu from '../../ThreeDotsMenu';
import MenuCommand from '../../MenuCommand';

function DocumentVersionMenuActions(props) {
  const actions = [];

  if (props.documentPermissions.canEditAdditionalDesignation) {
    actions.push(
      <MenuCommand
        id="document-menu-actions-additional-designation"
        onClick={() => props.documentActions.onEditAdditionalDesignation(props.document)}
        label={document.additionalDesignation ?
          <Translate value="document.editAdditionalDesignation"/> :
          <Translate value="document.addAdditionalDesignation"/>
        }
        icon="info"
      />
    );
  }

  if (props.documentPermissions.canDuplicate && isMultiOccurrence(props.document)) {
    actions.push(
      <MenuCommand
        id="document-menu-actions-duplicate"
        onClick={() => props.documentActions.onDuplicate(props.document)}
        label={<Translate value="document.duplicate"/>}
        icon="document-page"
      />
    );
  }

  if (canBeDeleted(props.document, props.documentPermissions)) {
    actions.push(
      <MenuCommand
        id="document-menu-actions-delete"
        onClick={() => props.documentActions.onDelete(props.document)}
        label={<Translate value="document.delete"/>}
        icon="trash"
      />
    );
  }

  if (isEmpty(actions)) {
    return null;
  }

  return (
    <ThreeDotsMenu>
      {actions.map((action, key) => <div key={key}>{action}</div>)}
    </ThreeDotsMenu>
  );
}

DocumentVersionMenuActions.propTypes = {
  document: documentVersionPropTypes().isRequired,
  documentPermissions: documentPermissionsPropTypes().isRequired,
  documentActions: documentActionsPropTypes().isRequired
};

export default DocumentVersionMenuActions;
