import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {DOCUMENT_STATE, documentPropTypes} from '../../../utils/documents';
import Icon from '../../Icon';
import {DOSSIER_STATE} from '../../../utils/dossiers';
import {isEmpty} from '../../../utils/arrays';

function DossierStateResume(props) {
  if (!props.dossierState || props.dossierState.dossierState === DOSSIER_STATE.EMPTY) {
    return null;
  }

  function getClassByState() {
    switch (props.dossierState.dossierState) {
      case DOSSIER_STATE.INVALID:
        return 'invalid';
      case DOSSIER_STATE.WARNING:
        return 'valid';
      case DOSSIER_STATE.VALID:
        return 'valid';
      case DOSSIER_STATE.AWAITING_VERIFICATION:
        return 'invalid';
      default:
        // eslint-disable-next-line no-console
        console.error(`The state "${props.dossierState.dossierState}" is not recognized`);
        return '';
    }
  }

  function getDownloadButton() {
    if (props.dossierState.dossierState === DOSSIER_STATE.EMPTY) {
      return null;
    }

    return <button
      type="button"
      data-id="dossier-state-resume-download"
      className="primary small inline-container"
      onClick={() => props.onDownloadDossierOverview()}
    >
      <Translate value="document.dossierResume.download"/>
      <Icon icon="download"/>
    </button>;
  }

  function renderInvalidDocumentsInProgress(invalidDocumentsInProgress) {
    return <>
      {!isEmpty(invalidDocumentsInProgress) &&
      <div className="quitus-section">
        <Translate value={`document.dossierResume.${invalidDocumentsInProgress.length > 1 ? 'documentsInvalidsInProgressText' : 'documentInvalidInProgressText'}`}/>
      </div>
      }
    </>;
  }

  function renderAlmostExpiredDocuments(almostExpiredDocuments) {
    return <>
      {!isEmpty(almostExpiredDocuments) &&
      <div className="quitus-section">
        <Translate value={`document.dossierResume.${almostExpiredDocuments.length > 1 ? 'documentsAlmostExpiredText' : 'documentAlmostExpiredText'}`}/>
        <ul className="mt-2">
          {almostExpiredDocuments.map(almostExpiredDocument => <li key={`dossier-resume-almost-expired-${almostExpiredDocument.uid}`}>
            <span
              className="clickable"
              onClick={() => props.onClickDocument(almostExpiredDocument)}
            >{almostExpiredDocument.type.name}</span>
          </li>)}
        </ul>
      </div>
      }
    </>;
  }

  function renderInvalidDocumentsToDo(invalidDocumentsToDo) {
    return <>
      {!isEmpty(invalidDocumentsToDo) &&
      <div className="quitus-section">
        <Translate value={`document.dossierResume.${invalidDocumentsToDo.length > 1 ? 'documentsInvalidsText' : 'documentInvalidText'}`}/>
        <ul className="mt-2">
          {invalidDocumentsToDo.map(invalidDocument => <li key={`dossier-resume-invalid-${invalidDocument.uid}`}>
            <span
              className="clickable"
              onClick={() => props.onClickDocument(invalidDocument)}
            >{invalidDocument.type.name}</span>
          </li>)}
        </ul>
      </div>
      }
    </>;
  }

  function getContent() {
    const invalidDocumentsToDo = props.dossierState.invalidDocuments.filter(
      document => [DOCUMENT_STATE.MISSING, DOCUMENT_STATE.EXPIRED, DOCUMENT_STATE.REJECTED, DOCUMENT_STATE.AWAITING_SIGNATURE].includes(
        document.state
      )
    );
    const invalidDocumentsInProgress = props.dossierState.invalidDocuments.filter(
      document => [DOCUMENT_STATE.AWAITING_GATHERING, DOCUMENT_STATE.AWAITING_VALIDATION].includes(document.state)
    );
    const {almostExpiredDocuments} = props.dossierState;
    return (
      <div className="col-12">
        {renderInvalidDocumentsToDo(invalidDocumentsToDo)}
        {renderAlmostExpiredDocuments(almostExpiredDocuments)}
        {renderInvalidDocumentsInProgress(invalidDocumentsInProgress)}
      </div>
    );
  }

  return (
    <div className={`quitus-state ${getClassByState()}`}>
      <h3 className="col-12 flex-container space-between">
        <Translate value={`document.dossierResume.title.${props.dossierState.dossierState}`}/>
        {getDownloadButton()}
      </h3>
      {getContent()}
    </div>
  );
}

DossierStateResume.propTypes = {
  dossierState: PropTypes.shape({
    dossierState: PropTypes.string.isRequired,
    invalidDocuments: PropTypes.arrayOf(documentPropTypes()),
    almostExpiredDocuments: PropTypes.arrayOf(documentPropTypes())
  }),
  onDownloadDossierOverview: PropTypes.func.isRequired,
  onClickDocument: PropTypes.func.isRequired
};

export default DossierStateResume;
