import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import Icon from '../Icon';

const UploadFooterView = props => {
  const submitButton = (
    <button
      className={`large primary inline-container${props.isSubmittable ? '' : ' disabled'}`}
      id="submit"
      type="submit"
      disabled={props.submitted}
    >
      <Translate value="action.confirm"/>
      <Icon icon={props.submitted ? 'loader' : 'check'}/>
    </button>
  );

  return (
    <div className="actions col-12 inline-container space-between">
      <button
        type="button"
        className="large secondary inline-container"
        onClick={props.onCancelClick}
        data-id="cancel"
      >
        <Translate value="action.cancel"/>
        <Icon icon="go-back"/>
      </button>
      {
        props.isSubmittable ?
          submitButton :
          <span tooltip={I18n.t('message.missingRequiredFields')} flow="left">{submitButton}</span>
      }
    </div>
  );
};

UploadFooterView.propTypes = {
  isSubmittable: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func
};

export default UploadFooterView;
