import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {InputBase, TextField, withStyles} from '@material-ui/core';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import * as moment from 'moment-timezone';
import MenuItem from '@material-ui/core/MenuItem';
import {Translate, I18n} from 'react-redux-i18n';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from './Icon';
import {PHONE_NUMBER_DEFAULT_REGEX, PHONE_NUMBER_INTERNATIONAL_REGEX} from '../utils/validators';
import CountryFlag from './CountryFlag';
import {ALL_COUNTRIES, COUNTRY_CODE} from '../utils/country';

export const PHONE_INTERNATIONAL_VALUE = 'INTERNATIONAL';

const ALL_COUNTRIES_CODE = ALL_COUNTRIES
  .map(country => (COUNTRY_CODE[country] ? COUNTRY_CODE[country].toUpperCase() : null))
  .filter(countryCode => countryCode);

export const PHONE_FR_VALUE = COUNTRY_CODE.FRANCE.toUpperCase();

const FlagInput = withStyles(() => ({}))(InputBase);

function TextPhoneNumber(props) {
  function isInternational(value) {
    return !ALL_COUNTRIES_CODE.includes(value);
  }

  let initialState;
  if (props.value) {
    initialState = parsePhoneNumberFromString(props.value);
  }

  let initialCountry = props.defaultLocal;
  if (initialState && initialState.country) {
    initialCountry = initialState.country;
  }

  initialCountry = initialCountry.toUpperCase();

  let initialValue = isInternational(initialCountry) ? '+' : '';
  if (initialState) {
    if (initialCountry === PHONE_FR_VALUE) {
      initialValue = '0';
    }

    initialValue += initialState.nationalNumber;
  }

  const [phoneText, setPhoneText] = React.useState(initialValue);
  const [country, setCountry] = React.useState(initialCountry);
  const phoneNumberFieldInput = useRef();

  function getMask() {
    return isInternational(country) ? PHONE_NUMBER_INTERNATIONAL_REGEX : PHONE_NUMBER_DEFAULT_REGEX;
  }

  const mask = getMask();

  function getPhoneObject(value) {
    if (value.startsWith('+')) {
      return parsePhoneNumberFromString(value);
    }

    return parsePhoneNumberFromString(value, country);
  }

  function constructEvent(value) {
    return {target: {value, type: 'phone'}};
  }

  function updateValue(rawValue) {
    function getCleanValue() {
      let value = rawValue;
      if (value.length > 0) {
        value = value
          .replace(/ /g, '')
          .replace(/-/g, '')
          .replace(/\)/g, '')
          .replace(/\(/g, '');
      }

      return value;
    }

    const value = getCleanValue(rawValue);
    if (!mask.test(value)) {
      return;
    }

    const phoneObject = getPhoneObject(value);
    if (phoneObject && phoneObject.country !== country) {
      setCountry(phoneObject.country);
    }

    setPhoneText(value);

    const valueFormatted = value === '+' ? '' : value;
    props.onChange(constructEvent(phoneObject ? phoneObject.number : valueFormatted));
  }

  // Detect changes, but produced side effect of controlled component... still.. it works.
  React.useEffect(() => {
    if (props.value) {
      updateValue(props.value);
    }
    // eslint-disable-next-line
  }, [props.value]);

  const handleChange = event => {
    event.preventDefault();
    event.stopPropagation();
    updateValue(event.target.value);
  };

  function getCountryFlag() {
    return isInternational(country) ? PHONE_INTERNATIONAL_VALUE : country;
  }

  function handleChangeFlag(event) {
    const countryKey = event.target.value;
    const value = isInternational(countryKey) ? PHONE_INTERNATIONAL_VALUE : countryKey;
    // Setting the focus to the number input field
    setTimeout(() => phoneNumberFieldInput.current.focus(), 0);
    if (value === country) {
      return;
    }

    setCountry(value);
    setPhoneText(countryKey === PHONE_INTERNATIONAL_VALUE ? '+' : '');
    props.onChange(constructEvent(''));
  }

  function getFlag(key, big = false) {
    return key === PHONE_INTERNATIONAL_VALUE ?
      <Icon icon="sphere" size={big ? 18 : 16}/> :
      <CountryFlag countryCode={key.toLowerCase()} size={big ? 17 : 12}/>;
  }

  const countries = ALL_COUNTRIES_CODE.concat(PHONE_INTERNATIONAL_VALUE)
    .sort((countryCode, previousCountryCode) => I18n.t(`country.flags.${countryCode}`).localeCompare(I18n.t(`country.flags.${previousCountryCode}`), props.currentLocale, {ignorePunctuation: true}))
    .map((countryCode, index) => (<MenuItem
      key={index}
      value={countryCode}
    >
      <div className="inline-container">
        {getFlag(countryCode)}
        <span className="ellipsis" style={{maxWidth: 200}}><Translate value={`country.flags.${countryCode}`}/></span>
      </div>
    </MenuItem>));

  return (
    <span className="inline-container" style={{width: '100%'}}>
      <TextField
        data-id="text-phone-number-input"
        value={phoneText}
        onChange={handleChange}
        error={props.error}
        label={props.label}
        disabled={props.disabled}
        helperText={props.helperText}
        inputRef={phoneNumberFieldInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <span style={{maxWidth: 300}}>
                <FormControl
                  variant="standard"
                  disabled={props.disabled}
                >
                  <Select
                    value={getCountryFlag()}
                    onChange={handleChangeFlag}
                    input={<FlagInput id="age-customized-input"/>}
                    renderValue={() => <span style={{height: 12}}>{getFlag(getCountryFlag(), true)}</span>}
                  >
                    {countries}
                  </Select>
                </FormControl>
              </span>
            </InputAdornment>)
        }}
      />
    </span>
  );
}

TextPhoneNumber.defaultProps = {
  defaultLocal: moment.locale()
};

TextPhoneNumber.propTypes = {
  value: PropTypes.string.isRequired,
  defaultLocal: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  currentLocale: PropTypes.string.isRequired
};

export default TextPhoneNumber;
