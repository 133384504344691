import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {Translate} from 'react-redux-i18n';
import Icon from '../components/Icon';

function InformationModal(props) {
  const handleSubmit = () => {
    props.onClose();
  };

  return (
    <Modal
      defaultStyles={{
        overlay: {
          backgroundColor: 'rgba(255,255,255,0.7)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1001
        },
        content: {
          backgroundColor: '#fff',
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 32px 0px',
          minWidth: 300,
          outline: 0,
          padding: 20,
          position: 'relative'
        }
      }}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
    >
      <div style={{textAlign: 'center'}}>
        <form onSubmit={handleSubmit}>
          <strong data-id="content" className="py-4">
            {props.children}
          </strong>
          <div className="actions col-12 inline-container flex-end">
            <button className="large primary inline-container" id="submit" type="submit">
              <Translate value="action.confirm"/>
              <Icon icon="check"/>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

InformationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default InformationModal;
