import {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Icon from './Icon';

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: false
    };

    this.handleRemoveNotification = this.handleRemoveNotification.bind(this);
  }

  handleRemoveNotification() {
    this.setState({hide: true});
    this.props.onRemoveNotification();
  }

  render() {
    return (
      <div className={`card notification${this.state.hide ? ' hide' : ''}${this.props.viewed ? '' : ' background-lighter'}`}>
        <div className="notification-content" onClick={this.props.onNotificationClick}>
          <div className="information">
            <Icon icon={this.props.icon}/>
          </div>
          <div>
            <div>{this.props.message}</div>
            <div className="information">{moment(this.props.creationDate).fromNow()}</div>
          </div>
        </div>
        {this.props.showDelete && <div className="action-bar">
          <button className="button-square" onClick={this.handleRemoveNotification}>
            <Icon icon="trash"/>
          </button>
        </div>}
      </div>
    );
  }
}

Notification.propTypes = {
  creationDate: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  onNotificationClick: PropTypes.func,
  onRemoveNotification: PropTypes.func.isRequired,
  viewed: PropTypes.bool,
  showDelete: PropTypes.bool
};

Notification.defaultProps = {
  onNotificationClick: () => {},
  viewed: true,
  showDelete: false
};

export default Notification;
