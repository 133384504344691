import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse, Fade} from '@material-ui/core';
import {ErrorCode} from 'react-dropzone';
import {isEmpty} from '../../utils/strings';
import {isEmpty as isArrayEmpty} from '../../utils/arrays';
import UploadDropzone from './UploadDropzone';
import uploadUtils from '../../utils/upload';
import UploadFooterView from './UploadFooterView';
import UploadDocumentDetails from './UploadDocumentDetails';
import UploadHeaderView from './UploadHeaderView';

const {DOCUMENT_MAX_SIZE} = uploadUtils;
const {DOCUMENT_MIN_SIZE} = uploadUtils;

class UploadProjectSharedDocument extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      fieldErrors: {},
      showFieldErrors: false,
      isSubmittable: false,
      file: null,
      additionalDesignation: ''
    };

    this.validateFields = this.validateFields.bind(this);
    this.checkIfSubmittable = this.checkIfSubmittable.bind(this);
    this.handleAdditionalDesignationChange = this.handleAdditionalDesignationChange.bind(this);
    this.handleDropRejected = this.handleDropRejected.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.getFieldErrorText = this.getFieldErrorText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateFields() {
    const {fieldErrors} = this.state;
    let hasError = false;

    if (!this.state.file) {
      hasError = true;
    }

    const {additionalDesignation} = this.state;
    if (this.props.showAdditionalDesignation && (isEmpty(additionalDesignation) || additionalDesignation === false)) {
      fieldErrors.additionalDesignation = <Translate value="error.required"/>;
      hasError = true;
    }

    this.setState({
      fieldErrors
    });
    return hasError;
  }

  checkIfSubmittable() {
    this.setState({
      isSubmittable: !this.props.submitted && !this.validateFields()
    });
  }

  handleAdditionalDesignationChange(event) {
    this.setState({additionalDesignation: event.target.value}, this.checkIfSubmittable);
  }

  handleDropRejected(rejectedFile) {
    const {fieldErrors} = this.state;

    switch (rejectedFile.errors[0].code) {
      case ErrorCode.FileTooLarge:
        fieldErrors.file = <Translate value="document.errorMaxSizeMessage" count="15"/>;
        break;
      case ErrorCode.FileTooSmall:
        fieldErrors.file = <Translate value="document.errorMinSizeMessage" count="1"/>;
        break;
      case ErrorCode.FileInvalidType:
        fieldErrors.file = <><Translate value="document.errorTypeMessage"></Translate>{this.props.extensions.replace(/,/g, ' | ')}</>;
        break;
      default:
        fieldErrors.file = <Translate value="error.generic"/>;
    }

    this.setState({
      fieldErrors
    });
  }

  handleDrop(acceptedFiles, rejectedFiles) {
    if (!isArrayEmpty(acceptedFiles)) {
      const {fieldErrors} = this.state;
      fieldErrors.file = null;
      this.setState({
        file: acceptedFiles[0],
        fieldErrors
      }, this.checkIfSubmittable);
    } else if (!isArrayEmpty(rejectedFiles)) {
      this.handleDropRejected(rejectedFiles[0]);
    }
  }

  getFieldErrorText(fieldName) {
    if (this.state.fieldErrors === null || typeof (this.state.fieldErrors[fieldName]) === 'undefined' || (!this.state.showFieldErrors && fieldName !== 'file')) {
      return null;
    }

    return this.state.fieldErrors[fieldName];
  }

  handleSubmit(event) {
    event.preventDefault();

    this.checkIfSubmittable();

    if (!this.state.isSubmittable) {
      this.setState({showFieldErrors: true});
      return;
    }

    const info = {
      additionalDesignation: this.state.additionalDesignation.trim()
    };
    this.props.onHandleSubmitFile(this.state.file, info);
  }

  getFileMessage() {
    return (this.state.fieldErrors && this.state.fieldErrors.file ?
      <div className="error" id="error-file-message">{this.getFieldErrorText('file')}</div> :
      null
    );
  }

  render() {
    const errorFileMessage = this.getFileMessage();

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="col-12">
          <UploadHeaderView
            documentName={I18n.t('banette.uploadNewDocument')}
          />
          <Collapse in={!this.state.file}>
            <div>
              <UploadDropzone
                file={this.state.file}
                extensions={this.props.extensions}
                onDrop={this.handleDrop}
                maxSize={DOCUMENT_MAX_SIZE}
                minSize={DOCUMENT_MIN_SIZE}
                errorFileMessage={errorFileMessage}
              />
            </div>
          </Collapse>
          {this.state.file &&
            <Fade in>
              <div>
                <UploadDocumentDetails
                  file={this.state.file}
                  onRemoveFile={() => this.setState({file: null})}
                />
                {this.props.showAdditionalDesignation &&
                  <>
                    <TextField
                      label={<span><Translate value="banette.documentName"/> *</span>}
                      id="additionalDesignation"
                      name="additionalDesignation"
                      error={Boolean(this.getFieldErrorText('additionalDesignation'))}
                      helperText={this.getFieldErrorText('additionalDesignation')}
                      value={this.state.additionalDesignation}
                      onChange={this.handleAdditionalDesignationChange}
                      autoComplete="off"
                      InputProps={{
                        inputProps: {
                          maxLength: this.props.maxDocumentNameLength
                        }
                      }}
                    />
                    <Translate
                      value="message.textCounter"
                      counter={this.state.additionalDesignation ? this.state.additionalDesignation.length : 0}
                      maxLength={this.props.maxDocumentNameLength}
                    />
                  </>
                }
              </div>
            </Fade>
          }

          <UploadFooterView
            submitted={this.props.submitted}
            isSubmittable={this.state.isSubmittable}
            onCancelClick={this.props.onCancel}
          />
        </div>
      </form>
    );
  }
}

UploadProjectSharedDocument.defaultProps = {
  extensions: '.pdf,.docx',
  maxDocumentNameLength: 50
};

UploadProjectSharedDocument.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onHandleSubmitFile: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  extensions: PropTypes.string,
  maxDocumentNameLength: PropTypes.number,
  showAdditionalDesignation: PropTypes.bool.isRequired
};

export default UploadProjectSharedDocument;
