import PropTypes from 'prop-types';
import SvgDonutChart from './svgDonutChart';

function DonutChart(props) {
  function getTotal(data) {
    return data.map(entry => entry.value).reduce((accumulator, currentValue) => accumulator + currentValue);
  }

  function getPercentage() {
    const total = getTotal(props.data);
    if (total === 0) {
      return 0;
    }

    const accountableElements = props.data
      .filter(item => item.isPartOfMainPercentage)
      .map(item => item.value)
      .reduce((accu, value) => accu + value);
    return Number(((accountableElements / total) * 100).toFixed(0));
  }

  function getData() {
    let data = [];
    props.data.forEach(entry => data.push({...entry}));

    const total = getTotal(data);
    data = data.map(entry => ({...entry, value: Number(((entry.value / total) * 100).toFixed(0))}));
    const missingPercentage = 100 - getTotal(data);
    if (missingPercentage !== 0) {
      // Find max entry and add the missing percentage
      data.reduce((p, v) => (p > v ? p : v)).value += missingPercentage;
    }

    return data;
  }

  return (
    <div className="donut-wrapper">
      <SvgDonutChart data={getData()}/>
      {props.mainPercentageLabel && <div className="donut-number-stats information-primary">
        <div className="number">{getPercentage()}%</div>
        {props.mainPercentageLabel && <div className="label">{props.mainPercentageLabel}</div>}
      </div>}
    </div>
  );
}

DonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  mainPercentageLabel: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string])
};

DonutChart.defaultProps = {
  mainPercentageLabel: null,
  hidePercentage: false
};

export default DonutChart;
