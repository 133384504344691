import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox, FormHelperText} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

function TextCheckbox(props) {
  return (
    <FormControl
      error={props.error}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled}
          />
        }
        className={props.error ? 'error' : ''}
        label={props.label}
      />
      {props.helperText &&
      <FormHelperText error={props.error}>
        {props.helperText}
      </FormHelperText>}
    </FormControl>
  );
}

TextCheckbox.defaultProps = {
  disabled: false
};

TextCheckbox.propTypes = {
  error: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default TextCheckbox;
