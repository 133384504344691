import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import Icon from '../Icon';

const UploadHeaderView = props => (
  <div className="flex-container space-between col-12">
    <h2 className="flex-container m-0">
      {props.documentName}
      {props.onDocumentExampleOpen && props.hasExample &&
        <div
          className="clickable ml-3"
          data-id="document-example-tooltip"
          tooltip={I18n.t('document.seeExample')}
          style={{cursor: 'pointer'}}
          onClick={props.onDocumentExampleOpen}
          flow="bottom"
        >
          <Icon icon="document-question" size={18}/>
        </div>
      }
    </h2>
    {props.children}
  </div>
);

UploadHeaderView.defaultProps = {
  hasExample: false
};

UploadHeaderView.propTypes = {
  documentName: PropTypes.string.isRequired,
  hasExample: PropTypes.bool,
  onDocumentExampleOpen: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default UploadHeaderView;
