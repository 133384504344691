import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Icon from '../Icon';
import {isEmpty} from '../../utils/strings';

function Maintenance(props) {
  if (!props.maintenance) {
    return null;
  }

  const messageTriggerDate = props.maintenance['message-trigger-date'];
  if (messageTriggerDate && moment(messageTriggerDate).isAfter(moment())) {
    // The message should not be displayed for now
    return null;
  }

  const message = props.currentLocale === 'en' ? props.maintenance.message : props.maintenance[`message-${props.currentLocale}`];
  if (!message || isEmpty(message)) {
    return null;
  }

  return (
    <div className={`col-12 ${props.class}`}>
      <div className="box future">
        <div className="flex-container">
          <span className="ofa"><Icon icon="info"/></span>
          <div data-id="message" dangerouslySetInnerHTML={{__html: message}}/>
        </div>
      </div>
    </div>
  );
}

Maintenance.defaultProps = {
  class: ''
};

Maintenance.propTypes = {
  maintenance: PropTypes.shape({
    id: PropTypes.any.isRequired,
    'maintenance-date': PropTypes.string,
    'message-trigger-date': PropTypes.string,
    message: PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    'message-fr': PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    'message-de': PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
  }),
  currentLocale: PropTypes.string.isRequired,
  class: PropTypes.string
};

export default Maintenance;
