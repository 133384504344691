import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {
  DOCUMENT_STATE,
  DOCUMENT_STATES_VALID,
  documentActionsPropTypes,
  documentPermissionsPropTypes,
  documentVersionPropTypes
} from '../../../utils/documents';
import {isEmpty} from '../../../utils/strings';
import DocumentVersionActions from './DocumentVersionActions';
import DocumentVersionText from './DocumentVersionText';
import DocumentVersionPayrollRatio from './DocumentVersionPayrollRatio';

function DocumentVersion(props) {
  function renderCertifyingThirdParty() {
    const {certifyingThirdParty} = props.document;
    if (isEmpty(certifyingThirdParty)) {
      return null;
    }

    return (
      <strong
        className="separator information"
        data-id="document-version-certifying-third-party"
      >
        {certifyingThirdParty}
      </strong>
    );
  }

  function getVersionClass() {
    const {state} = props.document;

    if (DOCUMENT_STATES_VALID.includes(state)) {
      if (props.isCurrent === true) {
        return 'current';
      }

      if (props.isCurrent === false) {
        return '';
      }

      return 'valid';
    }

    if ([DOCUMENT_STATE.EXPIRED,
      DOCUMENT_STATE.MISSING,
      DOCUMENT_STATE.REJECTED].includes(state)) {
      return 'rejected';
    }

    if ([DOCUMENT_STATE.AWAITING_SIGNATURE,
      DOCUMENT_STATE.AWAITING_VALIDATION,
      DOCUMENT_STATE.AWAITING_GATHERING].includes(state)) {
      return 'awaiting-verification';
    }

    return '';
  }

  function renderWorkerName() {
    if (isEmpty(props?.document?.workerFirstName)) {
      return null;
    }

    return <span
      data-id="document-version-worker-name"
      className="separator information"
    >{`${props.document.workerLastName} ${props.document.workerFirstName}`}</span>;
  }

  function renderAdditionalDesignation() {
    if (isEmpty(props.document.additionalDesignation)) {
      return null;
    }

    return <span
      data-id="document-version-additional-designation"
      className="separator information"
    >{props.document.additionalDesignation}</span>;
  }

  function handleOpen() {
    if (props.documentPermissions.isBackOffice) {
      props.documentActions.onOpen(props.document);
    }
  }

  return (
    <div data-id="version-box" className={`version-box ${getVersionClass.call(this)}`}>
      {props.isCurrent &&
        <div className="current-label">
          <Translate value="document.documentVersion.ongoingLabel"/>
        </div>
      }
      <div className="flex-container space-between">
        <div
          className={`font-size-s ${props.documentPermissions.isBackOffice ? 'clickable bo' : ''}`}
          onClick={() => handleOpen.call(this)}
        >
          <strong data-id="document-version-title">{props.document.type.name}</strong>
          {renderCertifyingThirdParty.call(this)}
          {renderWorkerName.call(this)}
          {renderAdditionalDesignation.call(this)}
        </div>
        <DocumentVersionActions
          document={props.document}
          documentPermissions={props.documentPermissions}
          documentActions={props.documentActions}
        />
      </div>
      <DocumentVersionText document={props.document}/>
      {props.documentPermissions.canSeePayrollRatio && <DocumentVersionPayrollRatio document={props.document}/>}
    </div>
  );
}

DocumentVersion.propTypes = {
  document: documentVersionPropTypes().isRequired,
  documentPermissions: documentPermissionsPropTypes().isRequired,
  documentActions: documentActionsPropTypes().isRequired,
  isCurrent: PropTypes.bool
};

export default DocumentVersion;
