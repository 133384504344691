import React from 'react';
import PropTypes from 'prop-types';
import {Menu, makeStyles} from '@material-ui/core';
import Icon from './Icon';

const style = makeStyles(() => ({
  paper: {
    marginTop: 10
  }
}));

const ThreeDotsMenu = props => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const classes = style();

  const commands = React.Children.map(props.children, (child, index) => (
    <div key={index} onClick={() => setMenuAnchorEl(null)}>
      {child}
    </div>
  ));

  const handleOpenMenu = event => {
    props.onOpen();
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      <button
        type="button"
        onClick={handleOpenMenu}
      >
        <Icon icon="menu" size={props.big ? 24 : null}/>
      </button>
      <Menu
        className={classes.paper}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {commands}
      </Menu>
    </>
  );
};

ThreeDotsMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  big: PropTypes.bool,
  onOpen: PropTypes.func
};

ThreeDotsMenu.defaultProps = {
  big: false,
  onOpen: () => {}
};

export default ThreeDotsMenu;
