export const DOSSIER_STATE = {
  VALID: 'VALID',
  WARNING: 'WARNING',
  INVALID: 'INVALID',
  EMPTY: 'EMPTY',
  DEPOSITOR_SERVICE_DEACTIVATED: 'DEPOSITOR_SERVICE_DEACTIVATED',
  AWAITING_VERIFICATION: 'AWAITING_VERIFICATION'
};

const dossiersUtils = {
  DOSSIER_STATE
};

export default dossiersUtils;
