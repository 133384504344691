import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Icon from './Icon';

function Todo(props) {
  return (
    <div className="card todo">
      <div className="todo-content">
        <div className="information">
          <Icon icon={props.icon}/>
        </div>
        <div className="todo-text">
          <div>
            {props.message}
          </div>
          <div className="information mt-3">{props.creationDate ? moment(props.creationDate).fromNow() : ' '}</div>
        </div>
      </div>
      <div className="action-bar">
        {props.children}
      </div>
    </div>
  );
}

Todo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  creationDate: PropTypes.string,
  icon: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired
};

export default Todo;
