import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';

const NoCurrentVersion = props => (
  <em className="information">
    { props.children ?
      props.children :
      <Translate value="document.documentDetails.noPresentDocument"/>
    }
    {props.onExampleClick &&
        <><span>, </span>
          <a href="/" onClick={props.onExampleClick}>
            <Translate value="document.documentDetails.documentExample"/>
          </a>
        </>
    }
  </em>
);

NoCurrentVersion.propTypes = {
  // If provided, will show the example link
  onExampleClick: PropTypes.func,
  // If NOT provided, a default text will be displayed
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ])
};

export default NoCurrentVersion;
