// Polyfills for IE/Edge/...
require('blueimp-canvas-to-blob');

export function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const createImage = url => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', error => reject(error));
  image.src = url;
});

export async function getCroppedImg(imageSrc, pixelCrop, resized) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');

  let maxWidth = 1000;
  let maxHeight = 750;
  if (resized) {
    maxWidth = 200;
    maxHeight = 150;
  }

  let ratio = 1;
  if (pixelCrop.width > maxWidth) {
    ratio = maxWidth / pixelCrop.width;
  }

  if (pixelCrop.height > maxHeight && (ratio < maxHeight / pixelCrop.height)) {
    ratio = maxHeight / pixelCrop.height;
  }

  canvas.width = pixelCrop.width * ratio;
  canvas.height = pixelCrop.height * ratio;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file);
    }, 'image/jpeg', 0.7);
  });
}

/**
 * Export functions for mock purposes
 */
const imageUtils = {
  readFile,
  getCroppedImg
};

export default imageUtils;
