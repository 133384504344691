import MomentUtils from '@date-io/moment';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import moment from 'moment-timezone';
import {KeyboardDatePicker} from '@material-ui/pickers/DatePicker';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

function TextDatePicker(props) {
  function handleDateChange(date) {
    props.onChange({
      target: {
        value: date,
        type: 'date'
      }
    });
  }

  if (moment.locale() === 'en') {
    moment.locale('en-GB');
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={moment().locale()} libInstance={moment}>
      <KeyboardDatePicker
        autoOk
        label={props.label}
        value={props.value ? props.value : null}
        onChange={handleDateChange}
        format={moment.localeData().longDateFormat('L')}
        placeholder={props.placeholder}
        error={props.error}
        helperText={props.helperText}
        disabled={props.disabled}
        minDate={props.minDate}
        maxDate={props.maxDate}
        cancelLabel={I18n.t('action.cancel')}
        okLabel={I18n.t('action.confirm')}
      />
    </MuiPickersUtilsProvider>
  );
}

TextDatePicker.defaultProps = {
  label: '',
  placeholder: '',
  minDate: moment().year(1900).startOf('y'),
  maxDate: moment().add(20, 'y').endOf('y')
};

TextDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(moment),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment)
};

export default TextDatePicker;
