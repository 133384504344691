import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {Translate} from 'react-redux-i18n';

import {chipColors} from '../utils';

const styleModal = {
  overlay: {
    backgroundColor: 'rgba(255,255,255,0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 32px 0px',
    minWidth: 300,
    outline: 0,
    padding: 20,
    position: 'relative'
  }
};

const closeStyle = {
  border: '0 none',
  background: 'none',
  cursor: 'pointer',
  fontSize: 24,
  height: 14,
  lineHeight: 0,
  padding: 0,
  position: 'absolute',
  right: 10,
  top: 10
};

function MultiProjectChips(props) {
  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false);

  return (
    <>
      <button
        data-id="open-modal"
        type="button"
        style={{color: chipColors.PROJECT}}
        onClick={() => setIsContactModalOpen(true)}
        className="no-wrap chip m-2 inline-container"
      >
        <Translate value="dossier.projects"/>
        <span data-id="nbProjects">{props.dossiers.length}</span>
      </button>
      <Modal
        defaultStyles={styleModal}
        isOpen={isContactModalOpen}
        onRequestClose={() => setIsContactModalOpen(false)}
        shouldCloseOnOverlayClick
      >
        <div data-id="modal-multi-project-content">
          <button type="button" style={closeStyle} onClick={() => setIsContactModalOpen(false)}>&times;</button>
          <h2><Translate value="dossier.relatedProjects"/></h2>
          <table>
            <tbody>
              {props.dossiers.map((dossier, index) => {
                const {project} = dossier;
                return (
                  <tr key={index}>
                    <td>
                      <span>
                        <span
                          style={{color: chipColors.PROJECT, maxWidth: 300}}
                          className="ellipsis chip m-2"
                          title={project.name}
                        >
                          {project.name}
                        </span>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
}

MultiProjectChips.propTypes = {
  dossiers: PropTypes.array.isRequired
};

export default MultiProjectChips;
