import PropTypes from 'prop-types';

export const addressPropTypes = () => PropTypes.shape({
  addressLine: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  postCode: PropTypes.string.isRequired
});

const addressUtils = {
  addressPropTypes
};

export default addressUtils;
