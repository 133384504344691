import PropTypes from 'prop-types';
import {MenuItem, Tooltip} from '@material-ui/core';
import Icon from './Icon';

const MenuCommand = props => {
  const menuItem = (
    <MenuItem
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <div className="flex-container">
        {props.icon && <Icon icon={props.icon}/>}
        <span>{props.label}</span>
      </div>
    </MenuItem>
  );

  if (!props.disabled) {
    return (
      <div
        className={props.BOCommand ? 'background-bo' : ''}
        style={{outline: 0}}
      >
        {menuItem}
      </div>
    );
  }

  return (
    <div
      className={props.BOCommand ? 'background-bo' : ''}
      style={{outline: 0}}
    >
      {props.disabled ?
        <Tooltip
          title={props.disabledMessage}
          placement="left"
        >
          <span>{menuItem}</span>
        </Tooltip> :
        {menuItem}
      }
    </div>
  );
};

MenuCommand.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.object.isRequired,
  disabledMessage: PropTypes.string,
  icon: PropTypes.string,
  BOCommand: PropTypes.bool
};

MenuCommand.defaultProps = {
  disabled: false,
  BOCommand: false,
  disabledMessage: ''
};

export default MenuCommand;
