import {Component} from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash.deburr';
import isEqual from 'lodash.isequal';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Autosuggest from 'react-autosuggest';
import {Translate} from 'react-redux-i18n';

const classes = {
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    right: 0,
    overflow: 'auto',
    maxHeight: 48 * 5
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
};

class TextSimpleAutocomplete extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      moreResults: 0,
      maxResult: props.maxResult,
      textValue: this.props.value?.label || '',
      stateSuggestions: [],
      optimizedSuggestions: this.constructOptimizedSuggestions(),
      errorSelection: false
    };

    // Binds
    this.getSuggestions = this.getSuggestions.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleOnSuggestionSelected = this.handleOnSuggestionSelected.bind(this);
    this.handleMoreResult = this.handleMoreResult.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.suggestions, this.props.suggestions)) {
      return;
    }

    this.setState({optimizedSuggestions: this.constructOptimizedSuggestions()});
  }

  constructOptimizedSuggestions() {
    // Optimize the labels for search efficiency
    let optimizedSuggestions = [...this.props.suggestions];
    optimizedSuggestions = optimizedSuggestions.map(suggestion => ({
      ...suggestion,
      optimizedLabel: deburr(suggestion.label).toLowerCase()
    }));
    return optimizedSuggestions;
  }

  renderInputComponent(inputProps) {
    const {
      classes: inputClasses, inputRef = () => {
      }, ref, ...other
    } = inputProps;

    return (
      <TextField
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: inputClasses.input
          }
        }}
        {...other}
      />
    );
  }

  renderSuggestion(suggestion, {query, isHighlighted}) {
    let result = [suggestion.label];
    if (query.length > 0) {
      const localQuery = deburr(query).toLowerCase();
      const indexFound = suggestion.optimizedLabel.indexOf(localQuery);
      result = [
        suggestion.label.substring(0, indexFound),
        suggestion.label.substring(indexFound, indexFound + query.length),
        suggestion.label.substring(indexFound + query.length, suggestion.label.length)
      ];
    }

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div className="ellipsis">
          {result.map((part, index) => (
            <span key={part + index} style={{fontWeight: index % 2 ? 500 : 400}}>
              {part}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  }

  getSuggestions(value) {
    // Note: max result is calculated, because there is no sync between this method and this.handleChange
    const maxResult = this.state.textValue === value ? this.state.maxResult : this.props.maxResult;
    const inputValue = deburr(value.trim()).toLowerCase();
    let count = 0;
    let moreResults = 0;
    const suggestions = this.state.optimizedSuggestions
      .filter(suggestion => {
        if (count < maxResult) {
          const isIncluded = suggestion.optimizedLabel.includes(inputValue);
          if (isIncluded) {
            count += 1;
          }

          return isIncluded;
        }

        if (suggestion.optimizedLabel.includes(inputValue)) {
          moreResults += 1;
        }

        return false;
      });
    this.setState({moreResults, errorSelection: inputValue.length > 0 && suggestions.length === 0});
    return suggestions;
  }

  getSuggestionValue(suggestion) {
    return suggestion.label;
  }

  shouldRenderSuggestions() {
    return this.state.textValue.length >= this.props.minimumCharsToRenderResults;
  }

  handleSuggestionsFetchRequested({value}) {
    this.setState({stateSuggestions: this.getSuggestions(value)});
  }

  handleSuggestionsClearRequested() {
    this.setState({stateSuggestions: [], moreResults: 0});
  }

  constructAutosuggestEvent(value = null) {
    return {
      target: {
        value,
        type: 'autosuggest'
      }
    };
  }

  handleChange(event, {newValue: textValue}) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({textValue, moreResults: 0, maxResult: this.props.maxResult, errorSelection: false});
    this.props.onChange(this.constructAutosuggestEvent());
  }

  handleBlur() {
    this.setState({textValue: this.props.value?.label || '', moreResults: 0, maxResult: this.props.maxResult, errorSelection: false});
  }

  handleOnSuggestionSelected(event, suggestionSelected) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({moreResults: 0, maxResult: this.props.maxResult});
    this.props.onChange(
      this.constructAutosuggestEvent(suggestionSelected.suggestion)
    );
  }

  handleMoreResult(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({maxResult: this.state.maxResult + this.props.maxResult}, () => {
      this.setState({stateSuggestions: this.getSuggestions(this.state.textValue)});
    });
  }

  render() {
    return (
      <Autosuggest
        renderInputComponent={this.renderInputComponent}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        suggestions={this.state.stateSuggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.handleOnSuggestionSelected}
        highlightFirstSuggestion
        inputProps={{
          classes,
          placeholder: this.props.placeholder,
          value: this.state.textValue,
          onChange: this.handleChange,
          label: this.props.label,
          error: this.props.error || this.state.errorSelection,
          disabled: this.props.disabled,
          helperText: this.props.helperText,
          onBlur: this.handleBlur,
          autoComplete: 'off'
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
            {this.state.moreResults > 0 &&
              <div className="background-lighter information">
                <MenuItem onClick={this.handleMoreResult} component="div">
                  <strong>
                    <Translate value="message.moreResults" results={this.state.moreResults}/>
                  </strong>
                </MenuItem>
              </div>
            }
          </Paper>
        )}
      />
    );
  }
}

TextSimpleAutocomplete.defaultProps = {
  suggestions: [],
  placeholder: '',
  label: null,
  minimumCharsToRenderResults: 3,
  maxResult: Number.MAX_SAFE_INTEGER
};

TextSimpleAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  suggestions: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  minimumCharsToRenderResults: PropTypes.number,
  maxResult: PropTypes.number
};

export default TextSimpleAutocomplete;
