import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {Link, Paper} from '@material-ui/core';
import {I18n, Translate} from 'react-redux-i18n';
import Icon from '../Icon';
import Button from '../Button';

const LoadingBlock = () => (
  <div
    className="d-flex align-items-center justify-content-center information-primary"
    style={{
      height: 400,
      width: 280
    }}
  >
    <Icon icon="loader" size={25}/>
  </div>
);

function UploadDocumentDetails(props) {
  const [fileUrl, setFileUrl] = useState(null);

  function getFileSize() {
    return `${Math.round(props.file.size / 1000)} Kb`;
  }

  useEffect(() => {
    if (props.file.type === 'application/pdf') {
      setFileUrl(URL.createObjectURL(props.file));
    }

    return () => {
      // Revoke the data uri to avoid memory leaks
      URL.revokeObjectURL(fileUrl);
    };
  }, [props.file.name]);

  return (
    <div className="box d-flex mb-5">
      <Paper>
        {fileUrl ?
          <Document
            file={fileUrl}
            loading={<LoadingBlock/>}
            className="d-flex align-items-center justify-content-center"
          >
            <Page
              pageNumber={1}
              className="d-flex align-items-center justify-content-center"
              width={310}
              loading={<LoadingBlock/>}
              renderAnnotationLayer={false}
            />
          </Document> :
          <div
            className="d-flex align-items-center justify-content-center information-primary"
            style={{
              height: 150,
              width: 150
            }}
          >
            <Icon icon="document" size={40}/>
          </div>
        }
      </Paper>
      <div className="d-flex flex-column flex-grow-1 ml-4">
        <div>
          <h3>
            <Translate value="document.filename"/>
          </h3>
          <div className="mb-5">
            {props.file.name}
          </div>
          <h3>
            <Translate value="document.fileSize"/>
          </h3>
          {getFileSize()}
        </div>
        <div
          className="align-self-end d-flex align-items-center"
          style={{marginTop: 'auto'}}
        >
          {fileUrl &&
            <div className="mr-5">
              <Link
                href={fileUrl}
                target="_blank"
              >
                <div className="flex-container">
                  <Icon icon="new-tab"/>
                  <Translate value="document.checkFile"/>
                </div>
              </Link>
            </div>
          }
          <Button
            onClick={props.onRemoveFile}
            icon="redo"
          >
            {I18n.t('document.changeFile')}
          </Button>
        </div>
      </div>
    </div>
  );
}

UploadDocumentDetails.propTypes = {
  file: PropTypes.object.isRequired,
  onRemoveFile: PropTypes.func.isRequired
};

export default UploadDocumentDetails;
