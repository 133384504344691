import React from 'react';

export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useDebounce(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );

  return debouncedValue;
}

export function didMountEffect(func, deps) {
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}

export function useStateHook(initialState) {
  const ref = React.useRef(false);
  const [state, updateState] = React.useState(initialState);

  React.useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  function setState(param) {
    if (ref.current) {
      updateState(current => ({
        ...current,
        ...(typeof param === 'function' ? param(current) : param)
      }));
    }
  }

  return [state, setState];
}
