import PropTypes, {string} from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {RadioGroup, FormHelperText, FormLabel, Radio} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

function getElement({value, label}, {radioProps, ...rest}) {
  return (
    <FormControlLabel
      key={`radio-${value}`}
      value={value}
      control={<Radio color="primary" {...radioProps}/>}
      label={label}
      {...rest}
    />
  );
}

function TextRadio(props) {
  const {
    helperText, error, title, values, onChange, value, childProps, ...rest
  } = props;

  return (
    <FormControl
      error={error}
    >
      {!!title && <FormLabel component="legend">{title}</FormLabel>}
      <RadioGroup
        aria-label={title}
        name="radio-buttons-group"
        onChange={onChange}
        value={value}
        {...rest}
      >
        {values.map(el => getElement(el, childProps))}
      </RadioGroup>
      {helperText &&
      <FormHelperText error={error}>
        {helperText}
      </FormHelperText>}
    </FormControl>
  );
}

TextRadio.defaultProps = {
  disabled: false,
  values: [],
  childProps: {}
};

TextRadio.propTypes = {
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({value: string, label: string})),
  error: PropTypes.bool,
  value: PropTypes.string,
  childProps: PropTypes.object,
  onChange: PropTypes.func
};

export default TextRadio;
