import PropTypes from 'prop-types';
import {Tooltip} from '@material-ui/core';

const CustomTooltip = props => (
  <Tooltip
    title={props.title}
    placement={props.placement}
    disableFocusListener={props.disabled}
    disableHoverListener={props.disabled}
    disableTouchListener={props.disabled}
  >
    <span>
      {props.children}
    </span>
  </Tooltip>
);

CustomTooltip.defaultProps = {
  title: '',
  disabled: false
};

CustomTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  placement: PropTypes.string
};

export default CustomTooltip;
