import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {useDropzone} from 'react-dropzone';
import Icon from '../Icon';

function UploadDropzone(props) {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: props.extensions,
    maxSize: props.maxSize,
    minSize: props.minSize,
    multiple: false,
    onDrop: props.onDrop
  });

  return (
    <>
      <div
        {...getRootProps()}
        className="mt-4 background-gradient-light"
        style={{
          borderRadius: '4px',
          border: 'dashed 2px',
          borderColor: '#d8d8d8'
        }}
      >
        <input {...getInputProps()}/>
        <div
          className="p-4 clickable background-image-transition"
          style={{
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            alignItems: 'center',
            justifyContent: 'center',
            height: 180,
            backgroundColor: isDragActive ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,1)',
            transition: 'background-color .2s ease-in-out, height .5s ease-in'
          }}
        >
          <>
            <div className="flex-container column center">
              <Icon icon="upload" size={80}/>
              <Translate value="document.dragDropHere"/>
            </div>
            <div className="mt-4">
              <button type="button" className="small primary flex-container" color="contrast">
                <Icon icon="folder-open"/>
                <Translate value="document.chooseOnComputer"/>
              </button>
            </div>
          </>
        </div>
      </div>
      {props.errorFileMessage && <div className="my-4">{props.errorFileMessage}</div>}
    </>
  );
}

UploadDropzone.propTypes = {
  file: PropTypes.object,
  extensions: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  errorFileMessage: PropTypes.node
};

UploadDropzone.defaultProps = {
  minSize: 0
};

export default UploadDropzone;
