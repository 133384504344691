import {isValidNumber} from 'libphonenumber-js';
import {parsePhoneNumberFromString as parseMobile} from 'libphonenumber-js/mobile';
import moment from 'moment-timezone';
import {isEmpty} from './strings';
import {isArray, isEmpty as isEmptyArray} from './arrays';

export const requiredValidator = value => {
  if (isEmpty(value) || (value instanceof moment && !value.isValid()) || (isArray(value) && isEmptyArray(value))) {
    return 'error.required';
  }

  return null;
};

function isAnInvalidPhoneNumber(value) {
  try {
    if (!isValidNumber(value)) {
      return true;
    }
  } catch (exception) {
    console.warn('Phone validation warning.', exception);
    return true;
  }

  return false;
}

export const phoneNumberValidator = value => {
  if (!value || value.length === 0 || value === '+') {
    return null;
  }

  if (isAnInvalidPhoneNumber(value)) {
    return 'error.invalidPhoneNumber';
  }

  return null;
};

export const mobilePhoneNumberValidator = value => {
  if (!value || value.length === 0 || value === '+') {
    return null;
  }

  if (isAnInvalidPhoneNumber(value)) {
    return 'error.invalidMobilePhoneNumber';
  }

  const phoneNumber = parseMobile(value);
  if (phoneNumber.getType() !== 'MOBILE') {
    return 'error.invalidMobilePhoneNumber';
  }

  return null;
};

export const PHONE_NUMBER_DEFAULT_REGEX = new RegExp(/^(\+)?[0-9]*$/);
export const PHONE_NUMBER_INTERNATIONAL_REGEX = new RegExp(/^(\+)[0-9]*$/);

export const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/; // eslint-disable-line
export const emailValidator = value => {
  if (!value || value.length === 0) {
    return null;
  }

  if (!EMAIL_REGEX.test(value)) {
    return 'error.invalidEmailFormat';
  }

  return null;
};

export const numberValidator = value => {
  if (value === null || isEmpty(value)) {
    return null;
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    return 'error.invalidNumberFormat';
  }

  return null;
};

export const numberRangeValidator = (min, max, minIncluded = true, maxIncluded = true) => value => {
  const number = Number(value);
  if ((minIncluded && number < min) || (!minIncluded && (number === min))) {
    return 'error.minRangeNumber';
  }

  if ((maxIncluded && number > max) || (!maxIncluded && (number === max))) {
    return 'error.maxRangeNumber';
  }

  return null;
};

export const dateValidator = momentDate => {
  if (momentDate && !momentDate.isValid()) {
    return 'error.invalidDate';
  }

  return null;
};

export const doValidate = (validator, value) => {
  if (!validator) {
    return null;
  }

  if (Array.isArray(validator)) {
    // Searching for the first validator on error
    return validator
      .map(validatorItem => doValidate(validatorItem, value))
      .find(element => element);
  }

  // Execution of the validation function
  return validator(value);
};

export const isIdentificationNumberValid = (type, value, inProgress = false) => {
  let regExp = type.format;
  if (inProgress) {
    if (!type.inProgress) {
      return true;
    }

    regExp = type.inProgress;
  }

  return value.match(regExp);
};

const validators = {
  requiredValidator,
  phoneNumberValidator,
  mobilePhoneNumberValidator,
  PHONE_NUMBER_DEFAULT_REGEX,
  PHONE_NUMBER_INTERNATIONAL_REGEX,
  EMAIL_REGEX,
  emailValidator,
  numberValidator,
  numberRangeValidator,
  dateValidator,
  isIdentificationNumberValid,
  doValidate
};

export default validators;
