import React from 'react';

export function getStrongs(value) {
  const splittedValue = value.split('__s');

  if (splittedValue.length % 2 === 0) {
    console.error(`There might be a __s missing in your translation : ${value}`);
  }

  return splittedValue.map((part, index) => {
    const element = index % 2 === 0 ? 'span' : 'strong';
    return React.createElement(element, {key: index}, part);
  });
}

export function getCurrency(value, unit) {
  const splittedValue = value.split('__c');

  if (splittedValue.length % 2 === 0) {
    console.error(`There might be a __c missing in your translation : ${value}`);
  }

  return splittedValue.map((part, index) => {
    if (index % 2 === 0) {
      return part;
    }

    let formattedNumber = Number(part / 100).toFixed(2);
    if (formattedNumber.endsWith('.00')) {
      formattedNumber = formattedNumber.substring(0, formattedNumber.length - 3);
    }

    return formattedNumber + unit;
  }).join('');
}

export function isEmpty(value) {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value === 'string' || value instanceof String) {
    return value.trim() === '';
  }

  return false;
}

const stringUtils = {
  getStrongs,
  getCurrency,
  isEmpty
};

export default stringUtils;
