import PropTypes from 'prop-types';

function StatusIcon(props) {
  const style = props.size ? {height: (props.size * 0.7), width: props.size} : {};

  return (
    <span className={`symbol symbol-status-${props.status}`} style={style}/>
  );
}

StatusIcon.propTypes = {
  status: PropTypes.oneOf(['check', 'cross', 'clock', 'warning', 'not-applicable']),
  size: PropTypes.number
};

export default StatusIcon;
