import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';

function Paginator(props) {
  if (props.totalItems - props.pageSize < 1) {
    // If the pagination is not needed, the component is empty
    return (null);
  }

  function getInformation() {
    if (props.information) {
      return props.information;
    }

    const toValue = (props.currentPage + 1) * props.pageSize > props.totalItems ? props.totalItems : (props.currentPage + 1) * props.pageSize;
    const fromValue = (props.currentPage * props.pageSize) + 1;

    return (
      <span>
        <Translate value="pagination.items"/>&nbsp;{fromValue}&nbsp;
        <Translate value="pagination.to"/>&nbsp;{toValue}&nbsp;
        <Translate value="pagination.of"/>&nbsp;{props.totalItems}
      </span>
    );
  }

  const isLastPage = ((props.currentPage + 1) * props.pageSize) >= props.totalItems;

  const isFirstPage = Boolean(props.currentPage < 1);
  return (
    <div className="paginator">
      <div className="information">{getInformation()}</div>
      <div className="paginator-buttons-container">
        <button disabled={isFirstPage} onClick={event => props.onChangePage(event, props.currentPage - 1)}>
          <div className="caret-left"/>
        </button>
        <button disabled={isLastPage} onClick={event => props.onChangePage(event, props.currentPage + 1)}>
          <div className="caret-right"/>
        </button>
      </div>
    </div>
  );
}

Paginator.propTypes = {
  information: PropTypes.string,
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default Paginator;
