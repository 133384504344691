import PropTypes from 'prop-types';

function RowChart(props) {
  return (
    <div className="chart-bar flex-column flex-sm-row">
      {props.data.map((entry, key) => <div
        key={key}
        className={`chart-bar-element reverted ${entry.color}`}
        style={{width: `${entry.percentage}%`}}
      />)}
    </div>
  );
}

RowChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    percentage: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  })).isRequired
};

export default RowChart;
