/**
 * Utility classes for shared upload data
 */

const uploadUtils = Object.freeze({
  DOCUMENT_MAX_SIZE: 15000000,
  DOCUMENT_MIN_SIZE: 1000
});

export default uploadUtils;
