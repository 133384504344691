export const ENTITY = {
  FRANCE: 'alg_fr',
  GERMANY: 'ofa_de',
  ITALY: 'ofa_it'
};

export function userHasMultiEntities(user) {
  return user.entities.length > 1;
}

const userUtils = {
  ENTITY,
  userHasMultiEntities
};

export default userUtils;
