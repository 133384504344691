import {I18n, Translate} from 'react-redux-i18n';
import {Tooltip} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  canBeDeleted,
  DOCUMENT_STATE,
  DOCUMENT_STATES_CAN_UPLOAD,
  documentActionsPropTypes,
  documentPermissionsPropTypes,
  documentVersionPropTypes,
  DOCUMENT_STATES_VALID
} from '../../../utils/documents';
import Icon from '../../Icon';
import DocumentVersionMenuActions from './DocumentVersionMenuActions';

const validStates = [...DOCUMENT_STATES_VALID, DOCUMENT_STATE.NOT_APPLICABLE];

function DocumentVersionActions(props) {
  function canNotUpload() {
    return !DOCUMENT_STATES_CAN_UPLOAD.includes(props.document.state) ||
      (!props.documentPermissions.canUpload && !props.documentPermissions.canUploadByMandate);
  }

  function canNotDownload(hasPermission, hasFile) {
    return props.forHeader ||
      !hasPermission ||
      !hasFile ||
      (!props.documentPermissions.canDownloadWithInvalidState && !validStates.some(state => state === props.document.state));
  }

  function uploadButton() {
    if (props.document.unpublished || canNotUpload()) {
      return null;
    }

    if (props.document.state === DOCUMENT_STATE.ALMOST_EXPIRED && !props.showUpdate) {
      return null;
    }

    if (props.forHeader && props.document.state === DOCUMENT_STATE.REJECTED) {
      return null;
    }

    if (!props.documentPermissions.canUploadByMandate && props.document.collectionMandateEnabled && !props.document.type.gatheredByDepositor) {
      return null;
    }

    const uploadLabel = [DOCUMENT_STATE.ALMOST_EXPIRED, DOCUMENT_STATE.EXPIRED].includes(props.document.state) ?
      I18n.t('document.uploadUpdate') : I18n.t('document.upload');
    const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      props.documentActions.onUpload(props.document);
    };

    const uploadBo = props.documentPermissions.canUploadByMandate &&
      (!props.documentPermissions.canUpload ||
        (props.document.collectionMandateEnabled && !props.document.type.gatheredByDepositor)
      );

    return <button
      className={`inline-container small primary no-wrap${uploadBo ? ' bo' : ''}`}
      title={uploadLabel}
      data-id="document-action-upload"
      onClick={handleClick}
    >
      <span className="d-none d-lg-inline-block">{uploadLabel}</span>
      <Icon icon="upload"/>
    </button>;
  }

  function downloadButton() {
    if (canNotDownload(props.documentPermissions.canDownload, props.document.hasFile)) {
      return null;
    }

    const {canDownloadWithInvalidState, isBackOffice, canUpload} = props.documentPermissions;
    const buttonTypeClass = (canDownloadWithInvalidState && isBackOffice && !canUpload) ?
      'bo' :
      'secondary';

    const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      props.documentActions.onDownload(props.document);
    };

    return <button
      className={`inline-container small no-wrap ${buttonTypeClass}`}
      title={I18n.t('document.open')}
      data-id="document-action-download"
      onClick={handleClick}
    >
      <span className="d-none d-lg-inline-block"><Translate value="document.open"/></span>
      <Icon icon="download"/>
    </button>;
  }

  function downloadExcel() {
    if (canNotDownload(props.documentPermissions.canDownloadExcel, props.document.hasExcelFile)) {
      return null;
    }

    const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      props.documentActions.onDownloadExcel(props.document);
    };

    return <button
      className="inline-container small secondary no-wrap"
      title={I18n.t('document.openExcel')}
      data-id="document-action-download-excel"
      onClick={handleClick}
    >
      <span className="d-none d-lg-inline-block"><Translate value="document.openExcel"/></span>
      <Icon icon="download"/>
    </button>;
  }

  function downloadProofButton() {
    if (canNotDownload(props.documentPermissions.canDownloadProof, props.document.hasProofFile)) {
      return null;
    }

    const downloadLabel = I18n.t('document.openProof');
    const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      props.documentActions.onDownloadProof(props.document);
    };

    return <button
      className="inline-container small secondary no-wrap"
      title={downloadLabel}
      data-id="document-action-download-proof"
      onClick={handleClick}
    >
      <span className="d-none d-lg-inline-block">{downloadLabel}</span>
      <Icon icon="download"/>
    </button>;
  }

  function signButton() {
    if (!props.documentPermissions.canSeeSignAction || props.document.state !== DOCUMENT_STATE.AWAITING_SIGNATURE) {
      return null;
    }

    const {canSign} = props.documentPermissions;
    const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      props.documentActions.onSign(props.document);
    };

    const button = (
      <button
        data-id="document-action-sign"
        disabled={!canSign}
        style={canSign ? {} : {pointerEvents: 'none'}}
        type="button"
        className="small primary inline-container"
        onClick={handleClick}
      >
        <Translate value="document.sign"/>
        <Icon icon="sign"/>
      </button>
    );

    return canSign ? button :
      <Tooltip title={I18n.t('document.noSignPermission')} placement="left"><span>{button}</span></Tooltip>;
  }

  function deleteButton() {
    // The delete button is only shown for the header
    if (!props.forHeader || !canBeDeleted(props.document, props.documentPermissions)) {
      return null;
    }

    const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      props.documentActions.onDelete(props.document);
    };

    return <button
      data-id="document-action-delete"
      type="button"
      className="small secondary inline-container"
      onClick={handleClick}
    >
      <span className="d-none d-lg-inline-block">
        <Translate value="document.delete"/>
      </span>
      <Icon icon="trash"/>
    </button>;
  }

  return (
    <div className="flex-container">
      {uploadButton()}
      {downloadProofButton()}
      {downloadExcel()}
      {downloadButton()}
      {signButton()}
      {deleteButton()}
      {!props.forHeader && <DocumentVersionMenuActions
        document={props.document}
        documentPermissions={props.documentPermissions}
        documentActions={props.documentActions}
      />}
    </div>
  );
}

DocumentVersionActions.defaultProps = {
  forHeader: false,
  showUpdate: false
};

DocumentVersionActions.propTypes = {
  document: documentVersionPropTypes().isRequired,
  documentPermissions: documentPermissionsPropTypes().isRequired,
  documentActions: documentActionsPropTypes().isRequired,
  showUpdate: PropTypes.bool,
  forHeader: PropTypes.bool
};

export default DocumentVersionActions;
