/* eslint-disable no-console */
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import groupBy from 'lodash.groupby';
import {organisationPropTypes} from './organisations';
import {isEmpty} from './arrays';

// DOCUMENT CONSTANTS
export const DOCUMENT_STATE = {
  VALID: 'VALID',
  EXPIRED: 'EXPIRED',
  ALMOST_EXPIRED: 'ALMOST_EXPIRED',
  MISSING: 'MISSING',
  REJECTED: 'REJECTED',
  SIGNED: 'SIGNED',
  AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
  AWAITING_VALIDATION: 'AWAITING_VALIDATION',
  AWAITING_GATHERING: 'AWAITING_GATHERING',
  NOT_APPLICABLE: 'NOT_APPLICABLE'
};

export const DOSSIER_TYPE = {
  LEGAL: 'LEGAL',
  BUSINESS: 'BUSINESS',
  ADDITIONAL: 'ADDITIONAL',
  PROJECT: 'PROJECT',
  RELATIONSHIP: 'RELATIONSHIP'
};

// DOCUMENT DEFINITIONS
export const DOCUMENT_STATES_CAN_UPLOAD = [
  DOCUMENT_STATE.MISSING,
  DOCUMENT_STATE.REJECTED,
  DOCUMENT_STATE.ALMOST_EXPIRED,
  DOCUMENT_STATE.EXPIRED,
  DOCUMENT_STATE.AWAITING_GATHERING
];

export const DOCUMENT_STATES_ALL = [
  DOCUMENT_STATE.VALID,
  DOCUMENT_STATE.EXPIRED,
  DOCUMENT_STATE.ALMOST_EXPIRED,
  DOCUMENT_STATE.MISSING,
  DOCUMENT_STATE.REJECTED,
  DOCUMENT_STATE.SIGNED,
  DOCUMENT_STATE.AWAITING_SIGNATURE,
  DOCUMENT_STATE.AWAITING_VALIDATION,
  DOCUMENT_STATE.AWAITING_GATHERING,
  DOCUMENT_STATE.NOT_APPLICABLE
];

export const DOCUMENT_STATES_VALID = [
  DOCUMENT_STATE.VALID,
  DOCUMENT_STATE.SIGNED,
  DOCUMENT_STATE.ALMOST_EXPIRED,
  DOCUMENT_STATE.EXPIRED
];

// DOCUMENT TYPES
export const documentTypePropTypes = () => PropTypes.shape({
  code: PropTypes.string.isRequired,
  documentTypeId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  parentCode: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  gatheredByDepositor: PropTypes.bool,
  abstractType: PropTypes.bool.isRequired,
  dossier: PropTypes.string.isRequired,
  multiOccurrence: PropTypes.bool,
  validityPeriod: PropTypes.any,
  designationMandatory: PropTypes.bool,
  hasExample: PropTypes.bool,
  applicationLevel: PropTypes.string,
  onlyForProjectSubcontractor: PropTypes.bool,
  collectableByMandate: PropTypes.bool,
  equivalence: PropTypes.object,
  canBeAdded: PropTypes.bool
});

export const documentDossierPropTypes = () => PropTypes.shape({
  type: PropTypes.string.isRequired,
  client: organisationPropTypes(),
  project: PropTypes.any,
  business: PropTypes.any
});

export const documentPropTypes = custom => PropTypes.shape({
  uid: PropTypes.string.isRequired,
  groupLevel: PropTypes.bool.isRequired,
  state: PropTypes.oneOf(DOCUMENT_STATES_ALL).isRequired,
  type: documentTypePropTypes().isRequired,
  depositDate: PropTypes.string,
  organisations: PropTypes.arrayOf(organisationPropTypes()).isRequired,
  validityStart: PropTypes.string,
  validityEnd: PropTypes.string,
  hasFile: PropTypes.bool.isRequired,
  hasExcelFile: PropTypes.bool.isRequired,
  noticePeriodConfiguration: PropTypes.string,
  firstNoticePeriod: PropTypes.number,
  secondNoticePeriod: PropTypes.number,
  thirdNoticePeriod: PropTypes.number,
  thirdPartyIdNumber: PropTypes.string,
  certifyingThirdParty: PropTypes.string,
  issuingThirdParty: PropTypes.string,
  rejectionReason: PropTypes.string,
  additionalDesignation: PropTypes.string,
  subjectToObligation: PropTypes.bool,
  gatheredByDepositor: PropTypes.bool.isRequired,
  collectionMandateEnabled: PropTypes.bool.isRequired,
  toSignOnline: PropTypes.bool.isRequired,
  dossiers: PropTypes.arrayOf(documentDossierPropTypes()),
  unpublished: PropTypes.bool.isRequired,
  correlationId: PropTypes.string.isRequired,
  internalNote: PropTypes.string,
  verificationCode: PropTypes.string,
  hasProofFile: PropTypes.bool,
  issueDate: PropTypes.string,
  referencePeriod: PropTypes.string,
  referenceYear: PropTypes.number,
  payroll: PropTypes.number,
  staff: PropTypes.number,
  withCapitalizationScheme: PropTypes.bool,
  amountBankGuarantee: PropTypes.number,
  turnover: PropTypes.number,
  iban: PropTypes.string,
  bic: PropTypes.string,
  contributionRate: PropTypes.number,
  turnoverTwa: PropTypes.number,
  overallStatusDocument: PropTypes.bool.isRequired,
  ...custom
});

export const documentVersionPropTypes = () => documentPropTypes({
  hasAnotherOccurrenceInSameDossier: PropTypes.bool.isRequired
});

export const documentActionsPropTypes = () => PropTypes.shape({
  onEditAdditionalDesignation: PropTypes.func,
  onSign: PropTypes.func,
  onUpload: PropTypes.func,
  onAddNewVersion: PropTypes.func,
  onDownload: PropTypes.func,
  onDownloadExcel: PropTypes.func,
  onDownloadProof: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func
});

export const constructDocumentActions = custom => ({
  onEditAdditionalDesignation: () => console.error('onEditAdditionalDesignation not implemented'),
  onSign: () => console.error('onSign not implemented'),
  onUpload: () => console.error('onUpload not implemented'),
  onAddNewVersion: () => console.error('onAddNewVersion not implemented'),
  onDownload: () => console.error('onDownload not implemented'),
  onDownloadExcel: () => console.error('onDownloadExcel not implemented'),
  onDownloadProof: () => console.error('onDownloadProof not implemented'),
  onDuplicate: () => console.error('onDuplicate not implemented'),
  onDelete: () => console.error('onDelete not implemented'),
  onOpen: () => console.error('onOpen not implemented'),
  ...custom
});

// DOCUMENT PERMISSIONS

// Construct permissions based on params context (for tests purposes)
export const documentPermissionsByRoles = (isManager, isDepositor, isBackOffice) => ({
  canEditAdditionalDesignation: (isManager && isDepositor) || isBackOffice,
  canSeeSignAction: isDepositor,
  canSign: isDepositor,
  canUpload: isManager,
  canUploadByMandate: isBackOffice,
  canDownload: isDepositor || isBackOffice,
  canDownloadExcel: isDepositor || isBackOffice,
  canDownloadWithInvalidState: isDepositor || isBackOffice,
  canDownloadProof: isDepositor || isBackOffice,
  canDuplicate: (isManager && isDepositor) || isBackOffice,
  canDelete: (isManager && isDepositor) || isBackOffice,
  isBackOffice
});

export const documentPermissionsPropTypes = () => PropTypes.shape({
  canEditAdditionalDesignation: PropTypes.bool.isRequired,
  canSeeSignAction: PropTypes.bool.isRequired,
  canSign: PropTypes.bool.isRequired,
  canUpload: PropTypes.bool.isRequired,
  canUploadByMandate: PropTypes.bool.isRequired,
  canDownload: PropTypes.bool.isRequired,
  canDownloadExcel: PropTypes.bool.isRequired,
  canDownloadWithInvalidState: PropTypes.bool.isRequired,
  canDownloadProof: PropTypes.bool.isRequired,
  canDuplicate: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  isBackOffice: PropTypes.bool.isRequired
});

// DOCUMENT UTILS
export const isMultiOccurrence = document => document.type.multiOccurrence ||
  (document.type.equivalence && document.type.equivalence.rule === 'MULTIPLE');

export const isWithoutDossier = document => !document.dossiers ||
  document.dossiers.length === 0 ||
  isEmpty(document.dossiers.filter(dossier => dossier.type !== 'ADDITIONAL'));

export const canBeDeleted = (document, documentPermissions) => documentPermissions.canDelete &&
  !document.hasFile &&
  !document.hasExcelFile &&
  (document.hasAnotherOccurrenceInSameDossier || isWithoutDossier(document));

/**
 * Provides a function to enrich a given document with 'hasAnotherOccurrenceInSameDossier' property
 * depending if another document has the same subtypeCode or equivalent
 * @param {Object} documents all dossier documents
 * @returns Function(document) that will run against provided documents arg
 */
export const computeHasAnotherOccurrenceInSameDossier = documents => documentToUpdate => {
  const hasAnotherOccurrenceInSameDossier = documents
    .filter(document => documentToUpdate.uid !== document.uid && (
      documentToUpdate.type.code === document.type.code ||
          (documentToUpdate.type.equivalence && document.type.equivalence && documentToUpdate.type.equivalence.code === document.type.equivalence.code)
    ))
    .some(document => documentToUpdate.dossiers
      .some(dossierToSearch => document.dossiers.some(dossier => isEqual(dossier, dossierToSearch))));
  return {...documentToUpdate, hasAnotherOccurrenceInSameDossier};
};

export const groupDocumentsByCorrelationId = documents => Object.values(groupBy(documents, 'correlationId'));

export const removeArchivedCorrelationIds = groupsOfDocuments => groupsOfDocuments.filter(groupOfDocuments => groupOfDocuments.some(document => !document.unpublished));

const ORDERED_DOSSIER_NAME = [
  DOSSIER_TYPE.LEGAL,
  DOSSIER_TYPE.BUSINESS,
  DOSSIER_TYPE.ADDITIONAL,
  DOSSIER_TYPE.PROJECT,
  DOSSIER_TYPE.RELATIONSHIP
];

function compareDossierName(a, b) {
  const value1 = ORDERED_DOSSIER_NAME.indexOf(a);
  const value2 = ORDERED_DOSSIER_NAME.indexOf(b);
  if (value1 < 0 && value2 < 0) { return 0; } /* if neither names are in ORDERED_DOSSIER_NAME */
  if (value1 < 0) { return 1; } /* if value1 isn't in ORDERED_DOSSIER_NAME */
  if (value2 < 0) { return -1; } /* if value2 isn't in ORDERED_DOSSIER_NAME */
  return value1 - value2;
}

export const groupAndOrderDocumentVersionsByDossierType = documentsVersions => {
  const unOrderedResult = groupBy(documentsVersions, documentVersions => documentVersions[0].type.dossier);

  return Object.keys(unOrderedResult)
    .sort(compareDossierName)
    .map(dossierName => ({
      dossier: dossierName,
      documentsVersions: unOrderedResult[dossierName]
    }));
};

const compareStringDate = (date1, date2, descending = true) => {
  if (date1 === date2) {
    return 0;
  }

  if (Boolean(date1) && Boolean(date2)) {
    return descending ? new Date(date2) - new Date(date1) : new Date(date1) - new Date(date2);
  }

  return date1 ? -1 : 1;
};

// Comparators for document with best effort
export const sortWithBestEfforts = (document1, document2) => compareStringDate(document1.validityEnd, document2.validityEnd) ||
  compareStringDate(document1.validityStart, document2.validityStart, false) ||
  compareStringDate(document1.issueDate, document2.issueDate, false) ||
  compareStringDate(document1.depositDate, document2.depositDate, false);

export const sortRecentFirstWithBestEfforts = (document1, document2) => compareStringDate(document1.validityEnd, document2.validityEnd) ||
  compareStringDate(document1.validityStart, document2.validityStart) ||
  compareStringDate(document1.issueDate, document2.issueDate) ||
  compareStringDate(document1.depositDate, document2.depositDate);

const documentUtils = {
  constructDocumentActions,
  isMultiOccurrence,
  isWithoutDossier,
  canBeDeleted,
  DOCUMENT_STATE,
  DOCUMENT_STATES_ALL,
  DOCUMENT_STATES_VALID,
  DOSSIER_TYPE,
  documentTypePropTypes,
  documentDossierPropTypes,
  documentPropTypes,
  documentVersionPropTypes,
  documentActionsPropTypes,
  groupDocumentsByCorrelationId,
  removeArchivedCorrelationIds,
  groupAndOrderDocumentVersionsByDossierType,
  sortWithBestEfforts,
  sortRecentFirstWithBestEfforts,
  computeHasAnotherOccurrenceInSameDossier
};

export default documentUtils;
