import PropTypes from 'prop-types';
import {Fab} from '@material-ui/core';
import {Translate} from 'react-redux-i18n';
import Icon from './Icon';

function HelpMenuButton({onClick}) {
  return (
    <Fab
      variant="extended"
      size="medium"
      color="inherit"
      onClick={onClick}
    >
      <div className="flex-container">
        <Icon icon="question-mark" size={18}/>
        <Translate value="menu.help"/>
      </div>
    </Fab>
  );
}

HelpMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default HelpMenuButton;
