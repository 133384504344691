import PropTypes from 'prop-types';
import Icon from './Icon';

function Button(props) {
  const classes = `inline-container ${props.size} ${props.type}`;
  const disabled = props.disabled ? 'disabled' : '';
  return (
    <button
      disabled={disabled}
      style={props.disablePointerEvents ? {pointerEvents: 'none'} : {}}
      className={classes}
      onClick={props.onClick}
    >
      {props.icon && <Icon icon={props.icon}/>}
      <span>{props.children}</span>
      {props.loading && <Icon icon="loader"/>}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary']),
  onClick: PropTypes.func.isRequired,
  disablePointerEvents: PropTypes.bool
};

Button.defaultProps = {
  disabled: false,
  disablePointerEvents: false,
  loading: false,
  size: 'small',
  type: 'secondary'
};

export default Button;
