import PropTypes from 'prop-types';
import {Dialog, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import Icon from './Icon';

const style = makeStyles(theme => ({
  closeButton: {
    cursor: 'pointer'
  },
  content: {
    paddingBottom: theme.spacing(2)
  }
}));

function DialogModal(props) {
  const classes = style();

  return (
    <Dialog
      onClose={props.onClose}
      open={props.isOpen}
    >
      <DialogTitle disableTypography>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="m-0">{props.title}</h2>
          {props.showCloseButton &&
            <div className={classes.closeButton} aria-label="close" onClick={props.onClose}>
              <Icon icon="cross" size={16}/>
            </div>
          }
        </div>
      </DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}

DialogModal.defaultProps = {
  showCloseButton: false
};

DialogModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  showCloseButton: PropTypes.bool
};

export default DialogModal;
