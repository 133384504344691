import React from 'react';
import PropTypes from 'prop-types';
import {Tabs as MuiTabs} from '@material-ui/core';
import {matchPath, useLocation} from 'react-router-dom';

import Tab from './Tab';

function useRouteMatch(pathname, patterns) {
  for (let i = 0; i < patterns.length; i += 1) {
    const {link, path} = patterns[i];
    const possibleMatch = matchPath(pathname, path || link);
    if (possibleMatch !== null) {
      return link;
    }
  }

  return null;
}

function Tabs(props) {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].

  const [currentTab, setCurrentTab] = React.useState(false);
  const {pathname} = useLocation();

  React.useEffect(() => {
    const nav = useRouteMatch(pathname, props.items);
    setCurrentTab(nav);
  }, [pathname]);

  return (
    <MuiTabs
      value={currentTab}
      indicatorColor="primary"
      textColor="primary"
    >
      {props.items.map(Tab)}
    </MuiTabs>
  );
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string.isRequired,
    path: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
  })).isRequired
};

export default Tabs;
