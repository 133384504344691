import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {DOCUMENT_STATE} from '../../../utils/documents';

const STATES = [
  DOCUMENT_STATE.ALMOST_EXPIRED,
  DOCUMENT_STATE.EXPIRED,
  DOCUMENT_STATE.VALID
];

const TYPES = [
  'COV_VIGI',
  'COV_VIGI_ETRANGER'
];

function DocumentVersionPayrollRatio({document}) {
  if (!TYPES.includes(document.type.code) || !STATES.includes(document.state)) {
    return null;
  }

  let payrollRatio = <Translate value="document.payrollRatio.NC"/>;
  let staff = <Translate value="document.payrollRatio.NC"/>;

  // Null >= 0 = true ....
  if (document.type.code === 'COV_VIGI' && (document.payroll === 0 || document.payroll > 0) && document.staff > 0) {
    payrollRatio = `${(document.payroll / document.staff).toFixed(0)}€`;
    staff = document.staff;
  }

  return (
    <div className="flex-container mt-3">
      <div className="chip"><Translate value="document.payrollRatio.label"/> {payrollRatio}</div>
      <div className="chip"><Translate value="document.staff"/> {staff}</div>
    </div>
  );
}

DocumentVersionPayrollRatio.propTypes = {
  document: PropTypes.object.isRequired
};

export default DocumentVersionPayrollRatio;
