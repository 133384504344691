export function isArray(value) {
  return value instanceof Array;
}

export function isEmpty(array) {
  return typeof array === 'undefined' || array === null || array.length === 0;
}

const arrayUtils = {
  isArray,
  isEmpty
};

export default arrayUtils;
