import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import Collapse from '@material-ui/core/Collapse';
import {
  DOCUMENT_STATE,
  DOCUMENT_STATES_VALID,
  documentActionsPropTypes,
  documentPermissionsPropTypes,
  documentVersionPropTypes,
  sortWithBestEfforts,
  DOSSIER_TYPE,
  computeHasAnotherOccurrenceInSameDossier
} from '../../../utils/documents';
import DocumentVersion from '../documentVersion/DocumentVersion';
import {isEmpty} from '../../../utils/arrays';
import Icon from '../../Icon';
import DocumentDetailsHeader from './DocumentDetailsHeader';

// eslint-disable-next-line
function DocumentDetails(props) {
  const [showTimeline, setShowTimeline] = React.useState(false);
  const [loadingTimeline, setLoadingTimeline] = React.useState(false);
  const [timelineVersions, setTimelineVersions] = React.useState([]);

  if (isEmpty(props.documentVersions)) {
    return null;
  }

  const headerDocumentVersion = props.documentVersions.find(documentVersion => documentVersion.overallStatusDocument);

  if (!headerDocumentVersion) {
    // eslint-disable-next-line no-console
    console.error(`The correlationId "${props.documentVersions[0].correlationId
      }" has no version with overallStatusDocument. The render of the document will not be done`);
    return null;
  }

  const invalidVersion = props.documentVersions.find(documentVersion => !DOCUMENT_STATES_VALID.includes(documentVersion.state));
  const statusValidSignedOrNotApplicable = !!props.documentVersions.find(documentVersion => (
    documentVersion.state === DOCUMENT_STATE.NOT_APPLICABLE ||
    [DOCUMENT_STATE.VALID, DOCUMENT_STATE.SIGNED].includes(documentVersion.state)
  ));
  const currentVersion = props.documentVersions
    .find(documentVersion => DOCUMENT_STATES_VALID
      .filter(state => state !== DOCUMENT_STATE.EXPIRED)
      .includes(documentVersion.state) && documentVersion.overallStatusDocument);

  const hasCollectionMandateEnabled = props.documentVersions.some(documentVersion => documentVersion.collectionMandateEnabled);

  const showAddNewVersion = (props.documentPermissions.canUpload || props.documentPermissions.canUploadByMandate) &&
    (!hasCollectionMandateEnabled || headerDocumentVersion.type.gatheredByDepositor) &&
    (statusValidSignedOrNotApplicable);

  function handleClickAddNewVersion(event) {
    event.preventDefault();
    event.stopPropagation();
    props.documentActions.onAddNewVersion(props.documentVersions[0]);
  }

  function handleShowTimeline() {
    setShowTimeline(true);
    setLoadingTimeline(true);
    props.getTimelineVersionsFn()
      .then(response => {
        const versions = response.map(computeHasAnotherOccurrenceInSameDossier(response));
        setTimelineVersions(versions);
        setLoadingTimeline(false);
      });
  }

  let message = null;
  if (headerDocumentVersion.state === DOCUMENT_STATE.REJECTED && headerDocumentVersion.rejectionReason) {
    message = (
      <div className="error inline-container mt-2">
        <Icon icon="error"/>
        <span><Translate value="document.documentDetails.documentIsRejected"/></span>
      </div>
    );
  } else if (props.documentVersions.some(document => headerDocumentVersion.uid !== document.uid && document.state === DOCUMENT_STATE.REJECTED)) {
    message = (
      <div className="error inline-container mt-2">
        <Icon icon="error"/>
        <span><Translate value="document.documentDetails.documentVersionIsRejected"/></span>
      </div>
    );
  } else if (props.documentVersions.some(
    document => headerDocumentVersion.uid !== document.uid && document.state === DOCUMENT_STATE.AWAITING_SIGNATURE
  )) {
    message = <div className="error inline-container mt-2">
      <Icon icon="error"/>
      <span><Translate value="document.documentDetails.documentVersionIsAwaitingSignature"/></span>
    </div>;
  }

  const dossiers = props.documentVersions[0].type.dossier === DOSSIER_TYPE.PROJECT ? props.documentVersions[0].dossiers.filter(dossier => dossier.type === 'PROJECT') : [];
  const {correlationId} = props.documentVersions[0];
  const isOpen = Boolean(props.correlationIdOpened && correlationId === props.correlationIdOpened);
  const showTimeLineButton = Boolean(props.getTimelineVersionsFn) && (!showTimeline || (showTimeline && loadingTimeline));
  const timelineVersionPermissions = {
    ...props.documentPermissions,
    canEditAdditionalDesignation: false,
    canSeeSignAction: false,
    canSign: false,
    canUpload: false,
    canUploadByMandate: false,
    canDuplicate: false,
    canDelete: false
  };

  return (
    <div
      className={`document-card${isOpen ? ' open' : ''}`}
      id={`document-details-${correlationId}`}
    >
      <div
        data-id="document-details-header"
        className="main"
        onClick={() => props.handleClick(isOpen ? null : correlationId)}
      >
        <DocumentDetailsHeader
          isOpen={isOpen}
          document={headerDocumentVersion}
          documentPermissions={props.documentPermissions}
          documentActions={props.documentActions}
          message={message}
          showUpdate={!invalidVersion}
          dossiers={dossiers}
          hasCollectionMandateEnabled={hasCollectionMandateEnabled}
        />
      </div>

      <Collapse in={isOpen}>
        <div className="collapse">
          <hr className="mt-0 mb-4"/>
          {hasCollectionMandateEnabled &&
            <div className="flex-container space-between mb-4">
              <div className="flex-container column">
                <strong data-id="document-details-gathered-mention" className="inline-container">
                  <span className="information-primary"><Icon icon="info"/></span>
                  <Translate value={`document.documentDetails.documentIsGathered.${headerDocumentVersion.type.country}`}/>
                </strong>
              </div>
            </div>
          }

          {invalidVersion &&
            <DocumentVersion
              document={invalidVersion}
              documentPermissions={props.documentPermissions}
              documentActions={props.documentActions}
            />
          }

          {!showTimeline && currentVersion &&
            <div className={invalidVersion ? 'mt-4' : ''}>
              <DocumentVersion
                document={currentVersion}
                documentPermissions={props.documentPermissions}
                documentActions={props.documentActions}
              />
            </div>
          }

          <div className="col-12 flex-container space-between mt-4">
            <div>
              {!currentVersion && props.noCurrentVersion}
            </div>
            {showAddNewVersion &&
              <button
                data-id="document-details-add-new-version"
                type="button"
                className={`button primary small no-wrap inline-container${
                !props.documentPermissions.canUpload && props.documentPermissions.canUploadByMandate ? ' bo' : ''}`}
                onClick={handleClickAddNewVersion}
              >
                <Translate value="document.documentDetails.addNewVersion"/>
                <Icon icon="upload"/>
              </button>
            }
          </div>

          {showTimeLineButton &&
            <button
              data-id="show-timeline-button"
              className="small secondary flex-container mt-3"
              onClick={handleShowTimeline}
            >
              <Icon icon={loadingTimeline ? 'loader' : 'document-page'}/>
              <Translate value="document.documentDetails.showTimelineButton"/>
            </button>
          }

          {showTimeline && !loadingTimeline &&
            <>
              <div className="mt-5 mb-5 font-size-l">
                <Translate value="document.documentDetails.timeline"/>
              </div>
              {isEmpty(timelineVersions) ?
                <div><Translate value="document.documentDetails.emptyTimeline"/></div> :
                <div className="document-versions">
                  {timelineVersions
                    .sort(sortWithBestEfforts)
                    .map(timelineVersion => (
                      <DocumentVersion
                        key={`timeline-version-${timelineVersion.uid}`}
                        document={timelineVersion}
                        documentPermissions={timelineVersionPermissions}
                        documentActions={props.documentActions}
                        isCurrent={timelineVersion.uid === currentVersion?.uid}
                      />
                    ))}
                </div>
              }
            </>
          }
        </div>
      </Collapse>
    </div>
  );
}

DocumentDetails.propTypes = {
  documentVersions: PropTypes.arrayOf(documentVersionPropTypes()).isRequired,
  documentPermissions: documentPermissionsPropTypes().isRequired,
  documentActions: documentActionsPropTypes().isRequired,
  correlationIdOpened: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  onDocumentExampleOpen: PropTypes.func,
  getTimelineVersionsFn: PropTypes.func,
  noCurrentVersion: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ])
};

export default DocumentDetails;
