import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import CountryFlag from './CountryFlag';
import {isEmpty} from '../utils/strings';

function EntityIllustration(props) {
  const getImageSrcFromProps = useCallback(() => (isEmpty(props.base64) ? props.imageUrl : `data:image/jpeg;base64,${props.base64}`), [props.base64, props.imageUrl]);

  const [imageUrl, setImageUrl] = React.useState(getImageSrcFromProps());
  const [onError, setOnError] = React.useState(false);

  React.useEffect(() => {
    setOnError(false);
    setImageUrl(getImageSrcFromProps());
  }, [getImageSrcFromProps, props.base64, props.imageUrl]);

  return (
    <div className={`entity-illustration${props.small ? ' small' : ''}`}>
      <div className="illustration">
        {imageUrl && !onError ?
          <img src={imageUrl} alt={props.name} onError={() => setOnError(true)}/> :
          <div>{props.name[0]}</div>
        }
      </div>
      {props.country && <CountryFlag tooltipLabel={props.tooltipLabel} country={props.country} size={props.small ? 14 : 17}/>}
    </div>
  );
}

EntityIllustration.defaultProps = {
  small: false,
  name: '.'
};

EntityIllustration.propTypes = {
  country: PropTypes.string,
  imageUrl: PropTypes.string,
  base64: PropTypes.string,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  tooltipLabel: PropTypes.string
};

export default EntityIllustration;
