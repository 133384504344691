import PropTypes from 'prop-types';
import {Tooltip} from '@material-ui/core';
import {COUNTRY_CODE} from '../utils/country';

const cdnUrl = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/flags/4x3/';

function CountryFlag(props) {
  const style = props.size === 0 ? {} : {
    backgroundImage: `url(${cdnUrl}${props.countryCode || COUNTRY_CODE[props.country]}.svg)`,
    height: props.size,
    width: props.size * (4 / 3)
  };

  if (props.tooltipLabel) {
    return (
      <Tooltip
        title={props.tooltipLabel}
        placement="bottom"
      >
        <div
          className="flag"
          style={style}
        />
      </Tooltip>
    );
  }
  return (
    <div
      className="flag"
      style={style}
    />
  );
}

CountryFlag.propTypes = {
  country: PropTypes.string,
  countryCode: PropTypes.string,
  size: PropTypes.number,
  tooltipLabel: PropTypes.string
};

CountryFlag.defaultProps = {
  size: 20
};

export default CountryFlag;
