// CONSTANTS
import {ENTITY, userHasMultiEntities} from './users';

export const COUNTRY = {
  ANDORRA: 'ANDORRA',
  AUSTRIA: 'AUSTRIA',
  BELGIUM: 'BELGIUM',
  BOLIVIA: 'BOLIVIA',
  BULGARIA: 'BULGARIA',
  CROATIA: 'CROATIA',
  CZECH_REPUBLIC: 'CZECH_REPUBLIC',
  ESTONIA: 'ESTONIA',
  FRANCE: 'FRANCE',
  GERMANY: 'GERMANY',
  GREECE: 'GREECE',
  HUNGARY: 'HUNGARY',
  ICELAND: 'ICELAND',
  INDIA: 'INDIA',
  ITALY: 'ITALY',
  IVORY_COAST: 'IVORY_COAST',
  LATVIA: 'LATVIA',
  LITHUANIA: 'LITHUANIA',
  LUXEMBOURG: 'LUXEMBOURG',
  MAURITIUS: 'MAURITIUS',
  MEXICO: 'MEXICO',
  MONACO: 'MONACO',
  NETHERLANDS: 'NETHERLANDS',
  POLAND: 'POLAND',
  PORTUGAL: 'PORTUGAL',
  ROMANIA: 'ROMANIA',
  SERBIA: 'SERBIA',
  SLOVAKIA: 'SLOVAKIA',
  SLOVENIA: 'SLOVENIA',
  SPAIN: 'SPAIN',
  SWEDEN: 'SWEDEN',
  SWITZERLAND: 'SWITZERLAND',
  UNITED_KINGDOM: 'UNITED_KINGDOM'
};

export const ALL_COUNTRIES = [
  COUNTRY.ANDORRA,
  COUNTRY.AUSTRIA,
  COUNTRY.BELGIUM,
  COUNTRY.BOLIVIA,
  COUNTRY.BULGARIA,
  COUNTRY.CROATIA,
  COUNTRY.CZECH_REPUBLIC,
  COUNTRY.ESTONIA,
  COUNTRY.FRANCE,
  COUNTRY.GERMANY,
  COUNTRY.GREECE,
  COUNTRY.HUNGARY,
  COUNTRY.ICELAND,
  COUNTRY.INDIA,
  COUNTRY.ITALY,
  COUNTRY.IVORY_COAST,
  COUNTRY.LATVIA,
  COUNTRY.LITHUANIA,
  COUNTRY.LUXEMBOURG,
  COUNTRY.MAURITIUS,
  COUNTRY.MEXICO,
  COUNTRY.MONACO,
  COUNTRY.NETHERLANDS,
  COUNTRY.POLAND,
  COUNTRY.PORTUGAL,
  COUNTRY.ROMANIA,
  COUNTRY.SERBIA,
  COUNTRY.SLOVAKIA,
  COUNTRY.SLOVENIA,
  COUNTRY.SPAIN,
  COUNTRY.SWEDEN,
  COUNTRY.SWITZERLAND,
  COUNTRY.UNITED_KINGDOM
];

export const COUNTRY_CODE = {
  ANDORRA: 'ad',
  AUSTRIA: 'at',
  BELGIUM: 'be',
  BOLIVIA: 'bo',
  BULGARIA: 'bg',
  CROATIA: 'hr',
  CZECH_REPUBLIC: 'cz',
  ESTONIA: 'ee',
  FRANCE: 'fr',
  GERMANY: 'de',
  GREECE: 'gr',
  HUNGARY: 'hu',
  ICELAND: 'is',
  INDIA: 'in',
  ITALY: 'it',
  IVORY_COAST: 'ci',
  LATVIA: 'lv',
  LITHUANIA: 'lt',
  LUXEMBOURG: 'lu',
  MAURITIUS: 'mu',
  MEXICO: 'mx',
  MONACO: 'mc',
  NETHERLANDS: 'nl',
  POLAND: 'pl',
  PORTUGAL: 'pt',
  ROMANIA: 'ro',
  SERBIA: 'rs',
  SLOVAKIA: 'sk',
  SLOVENIA: 'si',
  SPAIN: 'es',
  SWEDEN: 'se',
  SWITZERLAND: 'ch',
  UNITED_KINGDOM: 'gb'
};

// FUNCTIONS
export function getCountryByLanguage(language) {
  switch (language) {
    case 'fr':
      return COUNTRY.FRANCE;
    case 'es':
      return COUNTRY.SPAIN;
    case 'it':
      return COUNTRY.ITALY;
    default:
      return COUNTRY.GERMANY;
  }
}

export function getDefaultCountryByUser(user) {
  let defaultEntity = user.entities[0];
  if (userHasMultiEntities(user)) {
    // We try to find the best entity yto use for the given locale of the user
    if (user.language === 'fr' && user.entities.includes(ENTITY.FRANCE)) {
      defaultEntity = ENTITY.FRANCE;
    }

    if (user.language === 'de' && user.entities.includes(ENTITY.GERMANY)) {
      defaultEntity = ENTITY.GERMANY;
    }

    if (user.language === 'it' && user.entities.includes(ENTITY.ITALY)) {
      defaultEntity = ENTITY.ITALY;
    }
  }

  switch (defaultEntity) {
    case ENTITY.FRANCE:
      return COUNTRY.FRANCE;
    case ENTITY.GERMANY:
      return COUNTRY.GERMANY;
    case ENTITY.ITALY:
      return COUNTRY.ITALY;
    default:
      // eslint-disable-next-line no-console
      console.error(`The entity [${defaultEntity}] is not recognize for the current user id: ${user.id}`);
      return null;
  }
}

const countryUtils = {
  COUNTRY,
  ALL_COUNTRIES,
  getCountryByLanguage,
  getDefaultCountryByUser
};

export default countryUtils;
