/* eslint-disable no-console */
import {Component} from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import {Translate} from 'react-redux-i18n';
import UploadDropzone from '../upload/UploadDropzone';
import {readFile} from './imageUtils';

// eslint-disable-next-line spaced-comment
const isIE = /*@cc_on!@*/false || Boolean(document.documentMode);
const cropMinZoom = isIE ? 1 : 0.05;
const CONTAINER_HEIGHT = 350;

class ImageCropper extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      fileName: '',
      fileDataUrl: '',
      fileTypeError: null,
      hasImage: false,
      cropPosition: {x: 0, y: 0},
      cropZoom: 1,
      croppedAreaPixels: null
    };

    this.handleDropRejected = this.handleDropRejected.bind(this);
    this.setHasImage = this.setHasImage.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.fetchImageFunction = this.fetchImageFunction.bind(this);
  }

  componentDidMount() {
    this.props.fetchImageFunction(this.fetchImageFunction);
  }

  handleDropRejected() {
    const fileTypeError = (
      <div className="error">
        <Translate value="uploadImage.errorTypeMessage"/>
      </div>
    );
    this.setState({fileTypeError});
  }

  setHasImage(hasImage) {
    this.setState({hasImage});
    this.props.onHasImage(hasImage);
  }

  async handleDrop(acceptedFiles, rejectedFiles) {
    this.setState({fileTypeError: null});
    this.setHasImage(false);
    if (acceptedFiles.length) {
      const fileDataUrl = await readFile(acceptedFiles[0]);
      const fileName = acceptedFiles[0].name;
      this.setHasImage(Boolean(acceptedFiles.length && fileName && fileDataUrl));
      this.setState({fileDataUrl, fileName});
    } else if (rejectedFiles.length > 0) {
      this.handleDropRejected();
    }
  }

  fetchImageFunction() {
    if (!this.state.hasImage) {
      // eslint-disable-next-line no-console
      console.error('Impossible to fetch image: there is no image');
      return null;
    }

    return {
      croppedAreaPixels: this.state.croppedAreaPixels,
      fileDataUrl: this.state.fileDataUrl
    };
  }

  render() {
    return (
      <>
        <UploadDropzone
          fileName={this.state.fileName}
          onDrop={this.handleDrop}
          onDropRejected={this.handleDropRejected}
          extensions={'.png,.jpg,.jpeg'}
          errorFileMessage={this.state.fileTypeError}
        />
        {this.state.hasImage &&
        <>
          <div className="mt-3" style={{height: `${CONTAINER_HEIGHT}px`, position: 'relative'}}>
            <Cropper
              minZoom={cropMinZoom}
              aspect={this.props.cropAspect}
              image={this.state.fileDataUrl}
              crop={this.state.cropPosition}
              zoom={this.state.cropZoom}
              restrictPosition={isIE}
              showGrid={false}
              onCropChange={cropPosition => this.setState({cropPosition})}
              onZoomChange={cropZoom => this.setState({cropZoom})}
              onCropComplete={(croppedArea, croppedAreaPixels) => this.setState({croppedAreaPixels})}
              onMediaLoaded={mediaSize => {
                const cropZoomWidth = (CONTAINER_HEIGHT * this.props.cropAspect) / mediaSize.width;
                const cropZoomHeight = CONTAINER_HEIGHT / mediaSize.height;
                this.setState({cropZoom: cropZoomWidth < cropZoomHeight ? cropZoomWidth : cropZoomHeight});
              }}
            />
          </div>
          <div className="my-4 inline-container" style={{width: '100%'}}>
            <Slider
              min={cropMinZoom}
              value={this.state.cropZoom}
              max={3}
              step={0.02}
              onChange={(event, cropZoom) => this.setState({cropZoom})}
            />
          </div>
        </>
        }
      </>
    );
  }
}

ImageCropper.defaultProps = {
  onHasImage: () => {
  },
  cropAspect: 4 / 3
};

ImageCropper.propTypes = {
  fetchImageFunction: PropTypes.func.isRequired,
  onHasImage: PropTypes.func,
  cropAspect: PropTypes.number
};

export default ImageCropper;
