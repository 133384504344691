import PropTypes from 'prop-types';

function Caret(props) {
  return (
    <span className={`caret-${props.direction}`}/>
  );
}

Caret.propTypes = {
  direction: PropTypes.oneOf(['raise', 'drop', 'left', 'right'])
};

export default Caret;
