import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

function TextSelectMultiple(props) {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const inputId = `TextSelectMultiple${props.id}`;
  return (
    <FormControl
      variant="outlined"
      disabled={props.disabled}
      error={props.error}
      className="custom-text-field"
    >
      <InputLabel ref={inputLabel} htmlFor={inputId}>{props.label}</InputLabel>
      <Select
        value={props.value}
        onChange={props.onChange}
        input={<OutlinedInput labelWidth={labelWidth} id={inputId}/>}
        multiple
        renderValue={props.renderValue}>
        {props.children}
      </Select>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

TextSelectMultiple.defaultProps = {
  disabled: false,
  renderValue: selected => selected.join(', ')
};

TextSelectMultiple.propTypes = {
  value: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  renderValue: PropTypes.func,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default TextSelectMultiple;
