export const chipColors = {
  LEGAL: '#64b8d9',
  BUSINESS: '#64b8d9',
  PROJECT: '#bda1e1',
  RELATIONSHIP: '#61d3f2',
  PROJECT_SHARED: '#bda1e1'
};

export default {
  chipColors
};
