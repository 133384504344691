import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {documentDossierPropTypes} from '../../utils/documents';
import {isEmpty} from '../../utils/arrays';

import {chipColors} from './utils';
import MultiProjectChips from './MultiProjectChips';

function getDossierName(dossier) {
  let dossierName;

  switch (dossier.type) {
    case 'BUSINESS':
      dossierName = I18n.t(`dossier.labels.BUSINESS.${dossier.business}`);
      break;
    case 'PROJECT':
    case 'PROJECT_SHARED':
      dossierName = dossier.project.name;
      break;
    case 'RELATIONSHIP':
      dossierName = dossier.client.fullName;
      break;
    case 'ADDITIONAL':
      dossierName = null;
      break;
    default:
      dossierName = I18n.t('dossier.labels.LEGAL');
  }

  return dossierName;
}

function getProjectChip(dossiers) {
  if (isEmpty(dossiers)) { return null; }
  return dossiers.length > 1 ?
    <MultiProjectChips dossiers={dossiers}/> :
    <span
      style={{color: chipColors.PROJECT, maxWidth: 300}}
      className="ellipsis chip m-2"
      title={dossiers[0].project.name}
    >
      {dossiers[0].project.name}
    </span>;
}

function getNonProjectChips(dossiers) {
  return dossiers.flatMap((dossier, index) => {
    const dossierName = getDossierName(dossier);
    if (!dossierName) { return []; }
    return (
      <span
        key={dossiers.length + index}
        style={{color: chipColors[dossier.type], maxWidth: 300}}
        className="ellipsis chip m-2"
        title={dossierName}>
        {dossierName}
      </span>
    );
  });
}

function DocumentDossierChips(props) {
  const [projectDossiers, nonProjectDossiers] = props.dossiers.reduce((acc, curr) => {
    if (curr.type === 'PROJECT') {
      acc[0].push(curr);
    } else {
      acc[1].push(curr);
    }
    return acc;
  }, [[], []]);

  return (
    <div className="inline-container">
      {getProjectChip(projectDossiers)}
      {getNonProjectChips(nonProjectDossiers)}
    </div>
  );
}

DocumentDossierChips.propTypes = {
  dossiers: PropTypes.arrayOf(documentDossierPropTypes()).isRequired
};

export default DocumentDossierChips;
