import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autosuggest from 'react-autosuggest';

const styles = () => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 10
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
});

class TextAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.inputValue,
      isInputFocused: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.getItemValue = this.getItemValue.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
  }

  renderInput(inputProps) {
    const {InputProps, classes, ref, ...other} = inputProps;

    return (
      <TextField
        {...other}
        inputRef={ref}
        disabled={this.props.disabled}
        multiline={this.props.multiline}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...InputProps
        }}
      />
    );
  }

  shouldRenderSuggestions(value) {
    return value.trim().length >= this.props.minimumCharsToRenderResults;
  }

  renderMenuItem(item) {
    return (
      <MenuItem
        component="div"
      >
        {item.menuContent}
      </MenuItem>
    );
  }

  renderMenuItemsContainer(options) {
    const {containerProps, children} = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  }

  getItemValue() {
    return this.state.inputValue;
  }

  handleInputChange(event, options) {
    this.props.onInputEvent(event);
    this.setState({
      inputValue: options.newValue
    });
  }

  handleSelect(event, options) {
    this.props.onSelectFunction(event, options, result => {
      if (!result) {
        this.setState({inputValue: ''});
        if (this.props.showSelectedValueFunction) {
          this.setState({inputValue: this.props.showSelectedValueFunction(options.suggestion.data)});
        }
      }
    });
  }

  handleInputFocus() {
    this.setState({
      isInputFocused: true
    });
    if (this.props.controlledInput) {
      this.setState({inputValue: ''});
    }
  }

  handleInputBlur() {
    this.setState({isInputFocused: false});
  }

  render() {
    const {classes} = this.props;
    const inputValue = this.props.controlledInput && !this.state.isInputFocused ? this.props.inputValue : this.state.inputValue;
    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={this.renderInput}
        suggestions={this.props.items}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        onSuggestionsFetchRequested={this.props.onChangeFunction}
        onSuggestionsClearRequested={this.props.onClearFunction}
        onSuggestionSelected={this.handleSelect}
        getSuggestionValue={this.getItemValue}
        focusInputOnSuggestionClick={this.props.focusInputOnSuggestionClick}
        renderSuggestion={this.renderMenuItem}
        renderSuggestionsContainer={this.renderMenuItemsContainer}
        inputProps={{
          classes,
          placeholder: this.props.placeholder,
          error: this.props.error,
          helperText: this.props.helperText,
          label: this.props.label,
          value: inputValue,
          onChange: this.handleInputChange,
          onBlur: this.handleInputBlur,
          onFocus: this.handleInputFocus,
          autoFocus: this.props.autoFocus,
          autoComplete: 'off'
        }}
      />
    );
  }
}

TextAutocomplete.defaultProps = {
  autoFocus: false,
  controlledInput: false,
  inputValue: '',
  focusInputOnSuggestionClick: true,
  disabled: false,
  minimumCharsToRenderResults: 3,
  placeholder: '',
  multiline: false
};

TextAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onChangeFunction: PropTypes.func.isRequired,
  onSelectFunction: PropTypes.func.isRequired,
  onClearFunction: PropTypes.func.isRequired,
  onInputEvent: PropTypes.func,
  showSelectedValueFunction: PropTypes.func,
  autoFocus: PropTypes.bool,
  controlledInput: PropTypes.bool,
  inputValue: PropTypes.string,
  focusInputOnSuggestionClick: PropTypes.bool,
  disabled: PropTypes.bool,
  minimumCharsToRenderResults: PropTypes.number,
  multiline: PropTypes.bool
};

export default withStyles(styles)(TextAutocomplete);
