import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from '@material-ui/core';

import icons from 'front-onceforall-ui-kit/symbol-defs.svg';

const cssIcons = ['loader'];

const buildCssIcon = ({iconClassName, style}, rest, ref) => (
  <span
    {...rest}
    ref={ref}
    className={iconClassName}
    style={style}
  />
);

const buildSvgIcon = ({iconClassName, style, icon}, rest, ref) => (
  <svg
    {...rest}
    ref={ref}
    className={iconClassName}
    style={style}
  >
    <use xlinkHref={`${icons}#icon-${icon}`}/>
  </svg>
);

const IconComponent = React.forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function IconComponent({size, icon, color, className, ...rest}, ref) {
    const style = size ? {minHeight: size, maxHeight: size, minWidth: size, maxWidth: size} : {};

    let iconClassName = `icon icon-${icon} ${color}`;
    if (className) {
      iconClassName += ` ${className}`;
    }

    return cssIcons.includes(icon) ?
      buildCssIcon({iconClassName, style}, rest, ref) :
      buildSvgIcon({iconClassName, style, icon}, rest, ref);
  }
);

IconComponent.defaultProps = {
  color: '',
  className: ''
};

IconComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
};

const Icon = ({tooltip, ...rest}) => (
  tooltip ?
    <Tooltip
      title={tooltip}
    >
      <IconComponent {...rest}/>
    </Tooltip> :
    <IconComponent {...rest}/>

);

Icon.defaultProps = {
  color: ''
};

Icon.propTypes = {
  tooltip: PropTypes.string
};

export default Icon;
