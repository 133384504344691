import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    WebkitBackdropFilter: 'blur(4px)',
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    position: 'fixed',
    left: 0,
    top: 0,
    transition: 'background .5s',
    zIndex: 500
  },
  containerOpen: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%'
  },
  panel: {
    fontSize: '1.4rem',
    position: 'fixed',
    height: '100%',
    zIndex: '1000',
    backgroundColor: 'white',
    top: 0,
    left: '100%',
    transition: 'all .5s',
    overflow: 'scroll'
  },
  panelOpen: {
    transform: 'translateX(-100%)'
  },
  content: {
    margin: 30,
    minHeight: 'calc(100% - 74px)'
  }
};

function SidePanel(props) {
  const [isOpen, setIsOpen] = React.useState(false);

  const close = () => {
    if (props.canNotClose) {
      return;
    }

    setIsOpen(false);
    setTimeout(props.onClose, 500);
  };

  if (props.setCloseTrigger) {
    props.setCloseTrigger(close);
  }

  React.useEffect(() => setIsOpen(true), []);

  const containerStyle = {...styles.container, ...(isOpen ? styles.containerOpen : {})};
  const panelStyle = {...styles.panel, ...(isOpen ? styles.panelOpen : {})};
  const classNameOverlay = props.big ? 'col-xl-5 col-lg-2' : 'col-xl-6 col-lg-5 col-md-2';
  const classNamePanel = props.big ? 'col-xl-7 col-lg-10' : 'col-xl-6 col-lg-7 col-md-10';

  return (
    <div style={containerStyle}>
      <div style={styles.overlay} onClick={close} className={classNameOverlay}/>
      <div style={panelStyle} className={classNamePanel}>
        <div style={styles.content}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

SidePanel.defaultProps = {
  children: null,
  big: false,
  canNotClose: false
};

SidePanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClose: PropTypes.func,
  big: PropTypes.bool,
  canNotClose: PropTypes.bool,
  setCloseTrigger: PropTypes.func
};

export default SidePanel;
