import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import {Translate} from 'react-redux-i18n';
import {
  documentActionsPropTypes,
  documentPermissionsPropTypes,
  documentVersionPropTypes
} from '../../../utils/documents';
import DocumentVersionActions from '../documentVersion/DocumentVersionActions';
import DocumentState from '../state/DocumentState';
import {isEmpty} from '../../../utils/strings';
import DocumentDossierChips from '../../DocumentDossierChips';
import Icon from '../../Icon';

function DocumentDetailsHeader(props) {
  const {additionalDesignation, certifyingThirdParty, workerFirstName, workerLastName} = props.document;

  return (
    <>
      <div className="row align-items-center">
        <div className="col-xl-8 flex-container space-between font-size-s">
          <div>
            <span className="inline-container">
              {props.hasCollectionMandateEnabled && <span>
                <Tooltip title={<Translate value={`document.documentDetails.documentIsGathered.${props.document.type.country}`}/>} placement="right">
                  <div className="flex-container information">
                    <Icon icon="drawer"/>
                  </div>
                </Tooltip>
              </span>}
              <strong data-id="document-title">{props.document.type.name}</strong>
            </span>
            {!isEmpty(certifyingThirdParty) &&
            <span className="separator information">{certifyingThirdParty}</span>}
            {!isEmpty(workerFirstName) && <span className="separator information">{`${workerLastName} ${workerFirstName}`}</span>}
            {!isEmpty(additionalDesignation) && <span className="separator information">{additionalDesignation}</span>}
          </div>

          {!isEmpty(props.dossiers) &&
          <div className="mr-5">
            <DocumentDossierChips dossiers={props.dossiers}/>
          </div>
          }
        </div>
        <div className="col-xl-4 flex-container space-between">
          <DocumentState document={props.document}/>
          <div className="flex-container">
            <DocumentVersionActions
              document={props.document}
              documentActions={props.documentActions}
              documentPermissions={props.documentPermissions}
              showUpdate={props.showUpdate}
              forHeader
            />
            <span className={`caret-${props.isOpen ? 'raise' : 'drop'}`}/>
          </div>
        </div>
      </div>
      {props.message}
    </>
  );
}

DocumentDetailsHeader.propTypes = {
  document: documentVersionPropTypes().isRequired,
  documentPermissions: documentPermissionsPropTypes().isRequired,
  documentActions: documentActionsPropTypes().isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.node,
  showUpdate: PropTypes.bool.isRequired,
  dossiers: PropTypes.array,
  hasCollectionMandateEnabled: PropTypes.bool.isRequired
};

export default DocumentDetailsHeader;
