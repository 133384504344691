import {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

class Snackbars extends Component {
  constructor(props, context) {
    super(props, context);

    // In case of new construction (when losing application main context) => dismiss of all previous notifications
    props.actions.dismissAll();

    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleAnimationEnd(notificationId) {
    return event => {
      if (event.animationName === 'fade-out-snackbar') {
        this.dismiss(notificationId);
      }
    };
  }

  handleDismiss(notificationId) {
    return event => {
      event.preventDefault();
      event.stopPropagation();
      this.dismiss(notificationId);
    };
  }

  dismiss(notificationId) {
    this.props.actions.dismiss(notificationId);
  }

  render() {
    const snackbars = this.props.notifications.map(notification => <div
      key={notification.id}
      className="snackbar-container"
      onAnimationEnd={this.handleAnimationEnd(notification.id)}
      onClick={this.handleDismiss(notification.id)}
    >
      <button className={`snackbar ${notification.kind}`}>
        <span>{notification.message}</span>
        <span className="dismiss"><Icon icon="cross"/></span>
      </button>
    </div>);
    return (
      <div className="snackbars-container">
        {snackbars}
      </div>
    );
  }
}

Snackbars.propTypes = {
  notifications: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};

export default Snackbars;
