import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import Icon from '../Icon';
import {documentPropTypes} from '../../utils/documents';

function DownloadTemplate(props) {
  const handleDownloadTemplate = event => {
    event.preventDefault();
    event.stopPropagation();
    window.open(props.pathDocumentTemplate);
  };

  const handleSubmit = event => {
    event.preventDefault();
    props.onHandleSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-12">
        <div className="inline-container space-between col-12">
          <h3 className="m-0">{props.document.type.name}</h3>
        </div>
        <div className="col-12 mt-4 p-4 clickable" onClick={handleDownloadTemplate}
          style={{
            borderRadius: '4px',
            border: 'dashed 2px',
            borderColor: '#d8d8d8',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            alignItems: 'center'
          }}>
          <div className="inline-container column center">
            <Icon icon="download" size={80}/>
            <Translate value="document.downloadTemplateMessage"/>
          </div>
          <button
            type="button"
            className="small primary inline-container mt-5"
            onClick={handleDownloadTemplate}
            data-id="downloadTemplateButton"
          >
            <Translate value="document.downloadTemplate"/>
            <Icon icon="download"/>
          </button>
        </div>
        <div className="actions col-12 inline-container space-between">
          <button type="button" className="large secondary inline-container" onClick={props.onClose} data-id="cancel">
            <Translate value="action.cancel"/>
            <Icon icon="go-back"/>
          </button>
          <button id="submit" className="large inline-container primary" type="submit">
            <Translate value="action.continue"/>
            <Icon icon="arrow-right"/>
          </button>
        </div>
      </div>
    </form>
  );
}

DownloadTemplate.propTypes = {
  document: documentPropTypes().isRequired,
  onClose: PropTypes.func.isRequired,
  onHandleSubmit: PropTypes.func.isRequired,
  pathDocumentTemplate: PropTypes.string.isRequired,
  onClickProject: PropTypes.func
};

export default DownloadTemplate;
