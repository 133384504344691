import PropTypes from 'prop-types';
import {Tab as MuiTab} from '@material-ui/core';
import {Translate} from 'react-redux-i18n';
import {Link} from 'react-router-dom';

function Tab({label, link}) {
  function renderLabel() {
    if (typeof label === 'string') {
      return <div className="m-0"><Translate value={label}/></div>;
    }
    return label;
  }

  return (
    <MuiTab
      key={`tab-${link}`}
      data-id={`tab-${label}`}
      value={link}
      to={link}
      component={Link}
      label={renderLabel()}
    />
  );
}

Tab.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  link: PropTypes.string.isRequired
};

export default Tab;
