import PropTypes from 'prop-types';
import Icon from './Icon';

/**
 * An adornment, is a element put in a TextField.
 * This one indicate some information about a field
 */
function InfoAdornment(props) {
  return (
    <div
      tooltip={props.text} flow={props.flow}
      className={props.error ? 'error' : ''}
    >
      <Icon icon="info"/>
    </div>
  );
}

InfoAdornment.defaultProps = {
  flow: 'left',
  error: false,
  tooltip: ''
};

InfoAdornment.propTypes = {
  text: PropTypes.string,
  flow: PropTypes.string,
  error: PropTypes.bool
};

export default InfoAdornment;
