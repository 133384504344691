class DebouncedSearch {
  /**
   * A Debounce search permit to trigger a callback only the user has finish to type a word
   *
   * @param callback the callback to call when the user finished to type
   * @param callbackWaiting a callback which return true if there will be a search, false otherwise
   * @param timeoutTime the time of timeout between each updated values (300ms by default)
   */
  constructor(callback, callbackWaiting = () => {}, firstValue = '', timeoutTime = 300) {
    this.callback = callback;
    this.callbackWaiting = callbackWaiting;
    this.timeoutTime = timeoutTime;
    this.storedValue = firstValue;

    this.update = this.update.bind(this);
  }

  /**
   * Update the debounce search
   *
   * @param value The value to update
   * @return {boolean} true if there will be a call to the callback, false otherwise
   */
  update(value) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.storedValue === value) {
      // If the value has not change since the last callback, do nothing and return false
      this.callbackWaiting(false);
      return false;
    }

    this.timeout = setTimeout(() => {
      this.storedValue = value;
      this.callback(value);
      this.callbackWaiting(false);
    }, this.timeoutTime);

    // The value will change => return true
    this.callbackWaiting(true);
    return true;
  }
}

export default DebouncedSearch;
