import {I18n} from 'react-redux-i18n';
import moment from 'moment-timezone';
import {DOCUMENT_STATES_VALID, documentVersionPropTypes} from '../../../utils/documents';

function DocumentVersionText(props) {
  const hasValidityStart = Boolean(props.document.validityStart);
  const hasValidityEnd = Boolean(props.document.validityEnd);

  function getDocumentPeriod() {
    if (DOCUMENT_STATES_VALID.includes(props.document.state)) {
      if (Boolean(props.document.validityStart) && moment(props.document.validityStart).isAfter(moment())) {
        return 'FUTURE';
      }

      if (Boolean(props.document.validityEnd) && moment(props.document.validityEnd).isBefore(moment())) {
        return 'PAST';
      }
    }

    return 'CURRENT';
  }

  function getStateLine() {
    const state = I18n.t(
      `document.documentVersion.state.${props.document.state}`,
      {interpolation: {escapeValue: false}}
    );

    let line = I18n.t(`document.documentVersion.stateLine.${getDocumentPeriod(props.document)}`, {state});

    if (props.document.rejectionReason) {
      line += I18n.t('document.documentVersion.rejectionReason', {rejectionReason: props.document.rejectionReason});
    }

    return line;
  }

  function getValidityLine() {
    if (!hasValidityStart && !hasValidityEnd) {
      return '';
    }

    let I18nKey = '';

    if (hasValidityStart && !hasValidityEnd) {
      I18nKey = 'validityStartLine';
    }

    if (!hasValidityStart && hasValidityEnd) {
      I18nKey = 'validityEndLine';
    }

    if (hasValidityStart && hasValidityEnd) {
      I18nKey = 'validityFullLine';
    }

    return I18n.t(
      `document.documentVersion.${I18nKey}`,
      {
        validityStart: I18n.l(props.document.validityStart, {dateFormat: 'datetime.shortDate'}),
        validityEnd: I18n.l(props.document.validityEnd, {dateFormat: 'datetime.shortDate'})
      }
    );
  }

  function renderFirstLine() {
    let render = getStateLine();
    if (DOCUMENT_STATES_VALID.includes(props.document.state)) {
      render += getValidityLine();
    }

    return render;
  }

  return (
    <div className="text">
      <div dangerouslySetInnerHTML={{__html: renderFirstLine()}}/>
    </div>
  );
}

DocumentVersionText.propTypes = {
  document: documentVersionPropTypes().isRequired
};

export default DocumentVersionText;
