import React from 'react';
import {Translate} from 'react-redux-i18n';
import {doValidate} from './validators';

class AbstractFormComponent extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (this.getFormData === undefined) {
      throw new TypeError(`Class "${this.constructor.name}" must implement "getFormData"`);
    }

    if (this.getFormValidators === undefined) {
      throw new TypeError(`Class "${this.constructor.name}" must implement "getFormValidators"`);
    }

    if (this.handleSubmitCallback === undefined) {
      throw new TypeError(`Class "${this.constructor.name}" must implement "handleSubmitCallback"`);
    }

    this.state = {
      submitted: false,
      submitting: false,
      loading: true,
      formData: this.getFormData(),
      fieldErrors: {}
    };

    this.formValidators = this.getFormValidators();

    this.reset = this.reset.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validateField = this.validateField.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCallback = this.handleChangeCallback.bind(this);
    this.isOnError = this.isOnError.bind(this);
    this.helperText = this.helperText.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.getFormValidators = this.getFormValidators.bind(this);
    this.handleSubmitCallback = this.handleSubmitCallback.bind(this);
  }

  reset(loading = false) {
    this.setState({
      submitted: false,
      submitting: false,
      loading,
      formData: this.getFormData(),
      fieldErrors: {}
    });

    this.formValidators = this.getFormValidators();
  }

  validateAllFields() {
    Object.keys(this.state.formData).forEach(fieldName => {
      this.validateField(fieldName, this.state.formData[fieldName]);
    });
  }

  validateField(name, value, callback) {
    const fieldValidators = this.formValidators[name];
    const {fieldErrors} = this.state;
    const validation = doValidate(fieldValidators, value);

    if (fieldErrors[name] === validation) {
      if (callback) {
        callback();
      }

      return;
    }

    fieldErrors[name] = validation;
    this.setState({fieldErrors}, () => (callback ? callback() : null));
  }

  isFormValid() {
    return !this.isFormInvalid();
  }

  isFormInvalid() {
    return Object.values(this.state.fieldErrors).find(fieldError => Boolean(fieldError));
  }

  handleChange(name) {
    return event => {
      const {formData} = this.state;
      let {value} = event.target;
      if (event.target.type === 'checkbox') {
        value = event.target.checked;
      }

      formData[name] = value;

      this.setState({formData}, this.state.submitted ? () => {
        this.validateField(name, value, () => this.handleChangeCallback(name, value));
      } : () => this.handleChangeCallback(name, value));
    };
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  handleChangeCallback(name, value) {
  }

  isOnError(fieldName, waitForSubmit = true) {
    if (!waitForSubmit || this.state.submitted) {
      return Boolean(this.state.fieldErrors[fieldName]);
    }

    return false;
  }

  helperText(fieldName, waitForSubmit = true) {
    if (waitForSubmit && !this.state.submitted) {
      return null;
    }

    const fieldError = this.state.fieldErrors[fieldName];
    if (!fieldError) {
      return null;
    }

    return (<Translate value={fieldError}/>);
  }

  handleCancel(event) {
    event.preventDefault();
    this.setState({formData: this.getFormData()}, this.validateAllFields);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.submitted) {
      this.validateAllFields();
    }

    this.setState({submitted: true});
    if (this.isFormInvalid()) {
      return;
    }

    const trimedData = this.trimValues(this.state.formData);

    this.setState({submitting: true, loading: true, formData: trimedData}, this.handleSubmitCallback);
  }

  trimValues(formData) {
    const trimedData = {...formData};
    Object.entries(trimedData)
      .forEach(([key, value]) => {
        if (typeof value === 'string') {
          trimedData[key] = value.trim();
        }
      });
    return trimedData;
  }

  getFieldLabel(label, required = false) {
    const fieldLabel = <Translate value={label}/>;

    return required ?
      <span>{fieldLabel} *</span> :
      <>{fieldLabel}</>;
  }
}

export default AbstractFormComponent;
