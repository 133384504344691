import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse, Fade} from '@material-ui/core';
import {ErrorCode} from 'react-dropzone';
import {isEmpty} from '../../utils/strings';
import {isEmpty as isArrayEmpty} from '../../utils/arrays';
import Icon from '../Icon';
import TextSimpleAutocomplete from '../TextSimpleAutocomplete';
import DocumentDossierChips from '../DocumentDossierChips';
import UploadDropzone from './UploadDropzone';
import {documentPropTypes} from '../../utils/documents';
import UploadHeaderView from './UploadHeaderView';
import uploadUtils from '../../utils/upload';
import UploadFooterView from './UploadFooterView';
import UploadDocumentDetails from './UploadDocumentDetails';

const {DOCUMENT_MAX_SIZE} = uploadUtils;
const {DOCUMENT_MIN_SIZE} = uploadUtils;

class UploadDocument extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fieldErrors: {},
      showFieldErrors: false,
      isSubmittable: false,
      file: null,
      additionalDesignation: props.document.additionalDesignation || '',
      documentType: props.document.type,
      documentTypeValue: null,
      disableSubtype: false
    };

    this.validateFields = this.validateFields.bind(this);
    this.checkIfSubmittable = this.checkIfSubmittable.bind(this);
    this.handleDocumentTypeChange = this.handleDocumentTypeChange.bind(this);
    this.handleAdditionalDesignationChange = this.handleAdditionalDesignationChange.bind(this);
    this.handleDropRejected = this.handleDropRejected.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.setSubtypeAndLock = this.setSubtypeAndLock.bind(this);
    this.getFieldErrorText = this.getFieldErrorText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateFields() {
    const {fieldErrors} = this.state;
    let hasError = false;

    if (!this.state.file) {
      hasError = true;
    }

    if (this.state.documentType.designationMandatory) {
      const value = this.state.additionalDesignation;
      if (isEmpty(value) || value === false) {
        fieldErrors.additionalDesignation = <Translate value="error.required"/>;
        hasError = true;
      } else {
        fieldErrors.additionalDesignation = null;
      }
    }

    this.setState({
      fieldErrors
    });
    return hasError;
  }

  checkIfSubmittable() {
    this.setState({
      isSubmittable: !this.props.submitted && !this.validateFields()
    });
  }

  handleDocumentTypeChange(documentTypeValue) {
    let {documentType} = this.state;
    if (documentTypeValue && documentTypeValue.value) {
      documentType = this.props.documentEquivalences.find(type => type.documentTypeId === documentTypeValue.value);
    }

    this.setState({documentType, documentTypeValue}, this.checkIfSubmittable);
  }

  handleAdditionalDesignationChange(event) {
    this.setState({additionalDesignation: event.target.value}, this.checkIfSubmittable);
  }

  handleDropRejected(rejectedFile) {
    const {fieldErrors} = this.state;

    switch (rejectedFile.errors[0].code) {
      case ErrorCode.FileTooLarge:
        fieldErrors.file = <Translate value="document.errorMaxSizeMessage" count="15"/>;
        break;
      case ErrorCode.FileTooSmall:
        fieldErrors.file = <Translate value="document.errorMinSizeMessage" count="1"/>;
        break;
      case ErrorCode.FileInvalidType:
        fieldErrors.file = <><Translate value="document.errorTypeMessage"/>{this.props.extensions.replace(/,/g, ' | ')}</>;
        break;
      default:
        fieldErrors.file = <Translate value="error.generic"/>;
    }

    this.setState({
      fieldErrors
    });
  }

  handleDrop(acceptedFiles, rejectedFiles) {
    this.setSubtypeAndLock();

    if (!isArrayEmpty(acceptedFiles)) {
      const {fieldErrors} = this.state;
      fieldErrors.file = null;
      this.setState({
        file: acceptedFiles[0],
        fieldErrors
      }, this.checkIfSubmittable);
    } else if (!isArrayEmpty(rejectedFiles)) {
      this.handleDropRejected(rejectedFiles[0]);
    }
  }

  setSubtypeAndLock() {
    if (this.state.documentTypeValue || this.props.document.type.abstractType) {
      return;
    }

    this.handleDocumentTypeChange({
      label: this.props.document.type.name,
      value: this.props.document.type.documentTypeId
    });
    if (this.props.lockSubtype) {
      this.setState({disableSubtype: true});
    }
  }

  getFieldErrorText(fieldName) {
    if (this.state.fieldErrors === null || typeof (this.state.fieldErrors[fieldName]) === 'undefined' || (!this.state.showFieldErrors && fieldName !== 'file')) {
      return null;
    }

    return this.state.fieldErrors[fieldName];
  }

  handleSubmit(event) {
    event.preventDefault();

    this.checkIfSubmittable();

    if (!this.state.isSubmittable) {
      this.setState({showFieldErrors: true});
      return;
    }

    const info = {
      additionalDesignation: this.state.additionalDesignation.trim(),
      documentTypeCode: this.state.documentType.code
    };
    this.props.onHandleSubmitFile(this.props.document.uid, this.state.file, info);
  }

  getFileMessage() {
    return (this.state.fieldErrors && this.state.fieldErrors.file ?
      <div className="error" id="error-file-message">{this.getFieldErrorText('file')}</div> :
      null
    );
  }

  render() {
    const equivalences = this.props.documentEquivalences.map(doc => (
      {label: doc.name, value: doc.documentTypeId}
    ));
    const errorFileMessage = this.getFileMessage();

    return (
      <form
        className="p-0"
        onSubmit={this.handleSubmit}
      >
        <UploadHeaderView
          documentName={this.state.documentType.name}
          hasExample={this.state.documentType.hasExample}
          onDocumentExampleOpen={() => this.props.onDocumentExampleOpen()}
        >
          <DocumentDossierChips
            dossiers={this.props.document.dossiers}
          />
        </UploadHeaderView>
        {Boolean(this.props.message) && this.props.message}
        <div className="mt-4">
          <Collapse in={!this.state.file}>
            <div>
              <UploadDropzone
                file={this.state.file}
                extensions={this.props.extensions}
                onDrop={this.handleDrop}
                maxSize={DOCUMENT_MAX_SIZE}
                minSize={DOCUMENT_MIN_SIZE}
                errorFileMessage={errorFileMessage}
              />
              {Boolean(this.props.secondaryMessage) && this.props.secondaryMessage}
            </div>
          </Collapse>
        </div>
        {this.state.file &&
            <Fade in>
              <div>
                <UploadDocumentDetails
                  file={this.state.file}
                  onRemoveFile={() => this.setState({file: null})}
                />
                {this.props.showMetadataForm &&
                  <>
                    <div id="infosDiv" className="mt-3">
                      <TextSimpleAutocomplete
                        label={<Translate value="document.documentType"/>}
                        error={Boolean(this.getFieldErrorText('documentType'))}
                        helperText={this.getFieldErrorText('documentType')}
                        onChange={event => this.handleDocumentTypeChange(event.target.value)}
                        suggestions={equivalences}
                        minimumCharsToRenderResults={0}
                        maxResult={5}
                        value={this.state.documentTypeValue}
                        disabled={this.state.disableSubtype}
                      />
                    </div>
                    <TextField
                      label={<span><Translate value="document.additionalDesignation"/> {this.state.documentType.designationMandatory && '*'}</span>}
                      id="additionalDesignation"
                      name="additionalDesignation"
                      error={Boolean(this.getFieldErrorText('additionalDesignation'))}
                      helperText={this.getFieldErrorText('additionalDesignation')}
                      value={this.state.additionalDesignation}
                      onChange={this.handleAdditionalDesignationChange}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span tooltip={I18n.t('document.additionalDesignationNotice')} flow="left">
                              <Icon icon="info"/>
                            </span>
                          </InputAdornment>
                        ),
                        inputProps: {
                          maxLength: 100
                        }
                      }}
                    />
                    <Translate
                      value="message.textCounter"
                      counter={this.state.additionalDesignation ? this.state.additionalDesignation.length : 0}
                      maxLength={100}
                    />
                  </>
                }
              </div>
            </Fade>
        }

        <UploadFooterView
          submitted={this.props.submitted}
          isSubmittable={this.state.isSubmittable}
          onCancelClick={this.props.onCancel}
        />
      </form>
    );
  }
}

UploadDocument.defaultProps = {
  showMetadataForm: true,
  extensions: '.pdf',
  lockSubtype: false
};

UploadDocument.propTypes = {
  document: documentPropTypes().isRequired,
  documentEquivalences: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onHandleSubmitFile: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  showMetadataForm: PropTypes.bool,
  onDocumentExampleOpen: PropTypes.func,
  extensions: PropTypes.string,
  onClickProject: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  secondaryMessage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  lockSubtype: PropTypes.bool
};

export default UploadDocument;
