import {I18n} from 'react-redux-i18n';
import moment from 'moment-timezone';
import {DOCUMENT_STATE, DOCUMENT_STATES_VALID, documentPropTypes} from '../../../utils/documents';
import StatusIcon from '../../StatusIcon';

function DocumentState(props) {
  const documentState = props.document.state;
  function getStateLabel() {
    if (props.document.unpublished) {
      return I18n.t('document.documentDetails.unpublished', {interpolation: {escapeValue: false}});
    }

    if (DOCUMENT_STATE.ALMOST_EXPIRED === documentState) {
      return I18n.t(
        `document.documentDetails.state.${DOCUMENT_STATE.ALMOST_EXPIRED}`,
        {time: moment(props.document.validityEnd).fromNow(), interpolation: {escapeValue: false}}
      );
    }

    if (DOCUMENT_STATE.EXPIRED === documentState) {
      return I18n.t(
        `document.documentDetails.state.${DOCUMENT_STATE.EXPIRED}`,
        {time: moment(props.document.validityEnd).fromNow(), interpolation: {escapeValue: false}}
      );
    }

    const key = `document.documentDetails.state.${documentState}`;
    let until = '';
    if (DOCUMENT_STATES_VALID.includes(documentState) && Boolean(props.document.validityEnd)) {
      until = I18n.t(
        'document.documentDetails.validityEndLineForValid',
        {validityEnd: I18n.l(props.document.validityEnd, {dateFormat: 'datetime.shortDate'})}
      );
    }

    return I18n.t(key, {until, interpolation: {escapeValue: false}});
  }

  function getStatusIcon() {
    if (props.document.unpublished) {
      return 'clock';
    }

    switch (documentState) {
      case DOCUMENT_STATE.NOT_APPLICABLE:
        return 'not-applicable';
      case DOCUMENT_STATE.VALID:
      case DOCUMENT_STATE.SIGNED:
        return 'check';
      case DOCUMENT_STATE.ALMOST_EXPIRED:
        return 'warning';
      case DOCUMENT_STATE.EXPIRED:
      case DOCUMENT_STATE.MISSING:
      case DOCUMENT_STATE.REJECTED:
      case DOCUMENT_STATE.AWAITING_SIGNATURE:
        return 'cross';
      default:
        return 'clock';
    }
  }

  return (
    <span className="inline-container no-wrap">
      <StatusIcon status={getStatusIcon()}/>
      <span dangerouslySetInnerHTML={{__html: getStateLabel()}}/>
    </span>
  );
}

DocumentState.propTypes = {
  document: documentPropTypes().isRequired
};

export default DocumentState;
