import {useState} from 'react';
import PropTypes from 'prop-types';
import {Step as MUIStep, StepLabel as MUIStepLabel, Stepper as MUIStepper} from '@material-ui/core';
import {Translate} from 'react-redux-i18n';
import {isEmpty} from '../../utils/arrays';

function Stepper(props) {
  const [activeStep, setActiveStep] = useState(0);

  function handleNext(data) {
    const nextStep = activeStep + 1;
    if (nextStep >= props.steps.length) {
      props.onFinish();
    } else {
      props.onNextStep(nextStep, data);
      setActiveStep(nextStep);
    }
  }

  function handleSkip() {
    if (!props.steps[activeStep].optionnal) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    handleNext();
  }

  const renderStepLabel = (step, index) => {
    const label = (index === activeStep) ?
      <h2><Translate value={step.label}/></h2> :
      <h3><Translate value={step.label}/></h3>;
    return (
      <MUIStep key={step.label}>
        <MUIStepLabel>{label}</MUIStepLabel>
      </MUIStep>
    );
  };

  function renderStep() {
    const {steps, stepProps} = props;
    return steps[activeStep].render({
      goToNextStep: handleNext,
      skipStep: handleSkip,
      isProjectCreationFunnel: true,
      ...stepProps
    });
  }

  if (!props.steps || isEmpty(props.steps) || activeStep >= props.steps.length) {
    return null;
  }

  return (
    <>
      <MUIStepper
        alternativeLabel={true}
        activeStep={activeStep}
      >
        {props.steps.map(renderStepLabel)}
      </MUIStepper>
      {renderStep()}
    </>
  );
}

Stepper.defaultProps = {
  onNextStep: () => {},
  stepProps: {}
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  onNextStep: PropTypes.func,
  onFinish: PropTypes.func.isRequired,
  stepProps: PropTypes.object
};

export default Stepper;
